<template>
    <div class="flex flex-col gap-4 w-full">
        <div class="h-12 w-full bg-indigo-500 p-4 text-white font-medium rounded-md my-4 flex items-center justify-center" v-if="advert && 'archived' in advert && advert['archived'] == 1"> This Advert Is Archived </div>

        <div class="grid xl:grid-flow-col grid-flow-row gap-4">
            <TopLeftInfoCard v-if="advert" :edit_info_card="edit_info_card" :show_blame="show_blame" :data="advert_form" :slots="slots" @showBlame="show_blame = !show_blame">
                <template v-for="slot in slots" v-slot:[slot.name] :key="slot.name">
                    <!-- Display additional information when 'show_blame' is true -->
                    <div v-if="show_blame && slot.additional_information">
                        <h3 class="text-2xl">{{ advert[slot.additional_information.key] }}</h3>
                    </div>

                    <!-- Display form controls when 'edit_info_card' is true -->
                    <div v-if="edit_info_card && slot.input">
                        <!-- Handle checkbox specifically for 'is_reward_video' -->
                        <div v-if="slot.input.type == 'checkbox' && slot.form_key === 'is_reward_video'" class="flex flex-row gap-1 justify-center items-center">
                            <input 
                                type="checkbox" 
                                v-model="advert_form.is_reward_video"
                                :id="slot.name"
                                class="cursor-pointer"
                                :true-value="1"
                                :false-value="0"
                            />
                            <label :for="slot.name">{{ advert_form.is_reward_video == 1 ? 'True' : 'False' }}</label>
                        </div>

                        <!-- Handle checkbox specifically for 'skippable' -->
                        <div v-else-if="slot.input.type == 'checkbox' && slot.form_key === 'skippable'" class="flex flex-row gap-1 justify-center items-center">
                            <input 
                                type="checkbox" 
                                v-model="advert_form.skippable"
                                :id="slot.name"
                                class="cursor-pointer"
                                :true-value="1"
                                :false-value="0"
                            />
                            <label :for="slot.name">{{ advert_form.skippable == 1 ? 'True' : 'False' }}</label>
                        </div>

                        <!-- Handle other input types -->
                        <input 
                            v-else
                            class="max-w-lg block shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                            sm:max-w-xs sm:text-sm border-gray-300 rounded-md" 
                            :type="slot.input.type" 
                            :maxlength="slot.max_length" 
                            :min="slot.min" 
                            v-model="advert_form[slot.form_key]"/>
                    </div>

                    <!-- Display value as text when not editing or showing blame -->
                    <h3 class="text-2xl" v-if="!edit_info_card && !show_blame">
                        {{
                            slot.form_key === 'is_reward_video'
                                ? (advert_form.is_reward_video == 1 ? 'True' : 'False')
                                : slot.form_key === 'skippable'
                                    ? (advert_form.skippable == 1 ? 'True' : 'False')
                                    : unpackAdvertData(slot.value_key)
                        }}
                    </h3>
                </template>

                <template v-slot:toggleEditCard>
                    <div v-if="edit_info_card" class="flex flex-row flex-wrap gap-2">
                        <button @click="switchEditMode" class="btn-primary green">View Unchanged Info</button>
                        <button @click="updateAdvert" class="btn-primary indigo">Submit Updates</button>
                    </div>
                    <div v-else class="flex flex-row flex-wrap gap-2">
                        <button class="btn-primary green" @click="switchEditMode">Edit</button>
                        <button class="btn-primary red" @click="deleteAdvert">Delete</button>
                    </div>
                </template>
            </TopLeftInfoCard>

            <div class="flex flex-col gap-2 justify-center">
                <div class="bg-white flex flex-col gap-2 py-4 px-1 rounded-md">
                    <ProgressBar title="Progress to Target Impressions" :percentage="impressionsPercentage"/>
                    <ProgressBar :title="`Progress to End of Campaign`" :percentage="dateGapPercentage"/>
                </div>
            </div>
        
        </div>

        <div v-if="advert" class="flex flex-col justify-center items-center gap-4">

            <div class="flex flex-col justify-center items-center gap-2">
                <h3 class="text-2xl font-medium">Ad Sets</h3>
                <button class="btn-primary green" @click="show_add_new_run = !show_add_new_run"> Create Ad Set </button>
                <div class="flex flex-row gap-2 flex-wrap justify-center">
                    <RunForm v-if="show_add_new_run" :campaign_end_date="advert['end_date']" @submitForm="addRunToCampaign"/>
                    <RunInfo
                        class="sm:w-96 sm:text-base text-xs w-72"
                        v-for="run in campaign_runs" :key="run.uuid"
                        :class="currently_selected_run == run ? 'bg-blue-300 border border-black' : 'bg-white'"
                        @selected_run="toggleSelectedRun(run)"
                        :run="run"
                        :user_timezone="user_timezone"
                        :campaign_uuid="advert_uuid"
                        :is_active="run['is_active']"
                        @refreshCampaignRuns="fetchCampaignRuns"
                        @deletedRunInfo="deletingRunInfo"
                        :inactive_reasons="inactiveReasons(run)"/>
                </div>
            </div>

            <div class="bg-gray-200 rounded-md flex sm:flex-row flex-col sm:items-center justify-center gap-2 p-2 mb-4 w-full" id="settings-section">
                <div class="flex flex-col h-full items-center sm:items-start gap-2">
                    <div @click="showSection('ad set detail')" v-if="currently_selected_run != null" class="rounded-md options-picker" :class="{'options-picker-active' : this.is_advert_details == true}"> 
                        <span class="flex w-full h-full items-center justify-center">Ad Set Details</span> 
                    </div>
                    <div @click="showSection('creative')" class="rounded-md options-picker" :class="{'options-picker-active' : this.is_creative == true}"> 
                        <span class="flex w-full h-full items-center justify-center">Creatives</span> 
                    </div>
                    <div @click="showSection('game')" class="rounded-md options-picker" :class="{'options-picker-active' : this.is_game == true}"> 
                        <span class="flex w-full h-full items-center justify-center">Games</span> 
                    </div>
                    <div @click="showSection('placement')" class="rounded-md options-picker" :class="{'options-picker-active' : this.is_placement == true}"> 
                        <span class="flex w-full h-full items-center justify-center">Placements</span> 
                    </div>
                    <div @click="showSection('country')" class="rounded-md options-picker" :class="{'options-picker-active' : this.is_country == true}"> 
                        <span class="flex w-full h-full items-center justify-center">Country</span> 
                    </div>
                    <div @click="showSection('language')" class="rounded-md options-picker" :class="{'options-picker-active' : this.is_language == true}"> 
                        <span class="flex w-full h-full items-center justify-center">Language</span> 
                    </div>
                    <div @click="showSection('platform')" class="rounded-md options-picker" :class="{'options-picker-active' : this.is_platform == true}">
                        <span class="flex w-full h-full items-center justify-center">Platform</span>
                    </div>
                    <div @click="showSection('forecasting')" class="rounded-md options-picker" :class="{'options-picker-active' : this.is_forecasting == true}">
                        <span class="flex w-full h-full items-center justify-center capitalize"> {{singularAdvertType == 'run' ? 'Ad Set' : 'Campaign'}} Forecasting </span>
                    </div>
                    <div v-if="currently_selected_run != null" @click="showSection('frequency')" class="rounded-md options-picker" :class="{'options-picker-active' : this.is_frequency_cap == true}">
                        <span class="flex w-full h-full items-center justify-center">Frequency Cap</span>
                    </div>
                    <div v-if="currently_selected_run != null" @click="showSection('dates constraint')" class="rounded-md options-picker" :class="{'options-picker-active' : this.is_imp_constraint == true}">
                        <span class="flex w-full h-full items-center justify-center">Date Impressions Constraints</span>
                    </div>
                    <div v-if="currently_selected_run != null" @click="showSection('games constraint')" class="rounded-md options-picker" :class="{'options-picker-active' : this.is_game_constraint == true}">
                        <span class="flex w-full h-full items-center justify-center">Game Impressions Constraints</span>
                    </div>
                    <div v-if="currently_selected_run != null" @click="showSection('placements constraint')" class="rounded-md options-picker" :class="{'options-picker-active' : this.is_placement_constraint == true}">
                        <span class="flex w-full h-full items-center justify-center">Placement Impressions Constraints</span>
                    </div>
                    <div v-if="currently_selected_run != null" @click="showSection('disable rate limit')" class="rounded-md options-picker" :class="{'options-picker-active' : this.is_disable_rate_limit == true}">
                        <span class="flex w-full h-full items-center justify-center"> Always On </span>
                    </div>
                </div>

                <div class="w-full flex items-center justify-center">
                    <AdsetInfoEditor :advert="currently_selected_run" v-if="is_advert_details" @refreshRunInfo="fetchCampaignRuns" />
                    <AdvertImagePicker :advert_type="singularAdvertType" :advert="currently_selected_run ? currently_selected_run : advert" item_type="creatives" v-show="is_creative"/>
                    <AdvertImagePicker :advert_type="singularAdvertType" :advert="currently_selected_run ? currently_selected_run : advert" item_type="games" v-show="is_game"/>
                    <AdvertImagePicker :advert_type="singularAdvertType" :advert="currently_selected_run ? currently_selected_run : advert" item_type="placements" v-if="is_placement"/>
                    <SelectBoxComponent :advert_type="singularAdvertType" :advert_uuid="currently_selected_run ? currently_selected_run.uuid : advert.uuid" v-show="is_country" item_type="Countries"/>
                    <SelectBoxComponent :advert_type="singularAdvertType" :advert_uuid="currently_selected_run ? currently_selected_run.uuid : advert.uuid" v-show="is_language" item_type="Languages"/>
                    <AdvertPlatformPicker v-show="is_platform" :advert_uuid="currently_selected_run ? currently_selected_run.uuid : advert.uuid" :advert_type="singularAdvertType"/>
                    <AdvertForecasting v-if="is_forecasting" :advert="currently_selected_run ? currently_selected_run : advert" :advert_type="singularAdvertType"/>
                    <FrequencyCap v-if="is_frequency_cap" :run_info="this.currently_selected_run" @refreshRun="fetchCampaignRunsFromFrequency"/>
                    <DatesConstraints v-if="is_imp_constraint" :run_info="this.currently_selected_run"/>
                    <GamesConstraints v-if="is_game_constraint" :run_info="this.currently_selected_run" />
                    <PlacementsConstraints v-if="is_placement_constraint" :run_info="this.currently_selected_run" />
                    <DisableRateLimit v-if="is_disable_rate_limit" :run_info="this.currently_selected_run"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdvertImagePicker from '@/components/ImagePickers/AdvertImagePicker.vue'

import RunForm from '@/components/RunForm.vue'
import RunInfo from '@/components/RunInfo.vue'
import TopLeftInfoCard from '@/components/UtilityComponents/TopLeftInfoCard.vue' 
import ProgressBar from '@/components/UtilityComponents/ProgressBar.vue'
import SelectBoxComponent from '@/components/UtilityComponents/SelectBoxComponent.vue'
import AdvertForm from '@/classes/AdvertForm.js'
import FrequencyCap from '@/components/FrequencyCap.vue'
import DatesConstraints from '@/components/DatesConstraints.vue'
import GamesConstraints from '@/components/GamesConstraints.vue'
import PlacementsConstraints from '@/components/PlacementsConstraints.vue'
import AdvertPlatformPicker from '@/components/AdvertPlatformPicker.vue'
import DisableRateLimit from '@/components/DisableRateLimit.vue'
import AdvertForecasting from '@/components/AdvertForecasting.vue'
import AdsetInfoEditor from '@/components/AdsetInfoEditor.vue'

export default {
    name: 'ManageAdvert',
    components: {
        AdsetInfoEditor,
        AdvertImagePicker,
        SelectBoxComponent,
        RunForm,
        RunInfo,
        TopLeftInfoCard,
        ProgressBar,
        FrequencyCap,
        DatesConstraints,
        GamesConstraints,
        PlacementsConstraints,
        AdvertPlatformPicker,
        DisableRateLimit,
        AdvertForecasting
    },
    data() {
        return {
            user_timezone: null,
            advert: null,
            advert_uuid: this.$route.params.uuid,
            show_blame: false,
            creatives: [],
            games: [],
            all_categories: [],
            is_creative: true,
            is_game: false,
            is_placement: false,
            is_country: false,
            is_language: false,
            is_frequency_cap: false,
            is_imp_constraint: false,
            is_game_constraint: false,
            is_placement_constraint: false,
            is_disable_rate_limit: false,
            is_platform: false,
            is_forecasting: false,
            is_advert_details: false,
            show_add_new_run: false,
            edit_info_card: false,
            advert_form: new AdvertForm(),
            campaign_runs: [],
            slots: [
                {
                    name: 'cardName',
                    label: 'Name:',
                    input: {
                        type: 'text',
                    },
                    form_key: 'name',
                    max_length: '50',
                    data_tooltip: false,
                    value_key: 'name'
                },
                {
                    name: 'isRewardVideo',
                    label: 'Is Reward Video:',
                    input: {
                        type: 'checkbox',
                    },
                    form_key: 'is_reward_video',
                    max_length: '50',
                    data_tooltip: false,
                    value_key: 'is_reward_video'
                },
                {
                    name: 'skippable',
                    label: 'Skippable:',
                    input: { type: 'checkbox' },
                    form_key: 'skippable',
                    max_length: '50',
                    data_tooltip: false,
                    value_key: 'skippable'
                },
                {
                    name: 'cardImpressionsSent',
                    label: 'Impressions Sent: ',
                    additional_information: {
                        key: 'last_modified_by',
                        label: 'Last Modified By: '
                    },
                    input: undefined,
                    form_key: undefined,
                    data_tooltip: false,
                    data_tooltip_in_card: true,
                    value_key: 'impressions_sent'
                },
                {
                    name: 'cardTargetImpressions',
                    label: 'Target Impressions: ',
                    additional_information: {
                        key: 'last_modified_action',
                        label: 'Last Modification'
                    },
                    input: undefined,
                    form_key: undefined,
                    data_tooltip: true,
                    data_tooltip_in_card: false,
                    value_key: 'target_impressions'
                },
                {
                    name: 'cardStartDate',
                    label: 'Start Date: ',
                    additional_information: undefined,
                    input: {
                        type: 'date'
                    },
                    min: this.$dateTodayFormatted(),
                    form_key: 'start_date',
                    data_tooltip: false,
                    value_key: 'start_date'
                },
                {
                    name: 'cardEndDate',
                    label: 'End Date: ',
                    additional_information: undefined,
                    input: {
                        type: 'date'
                    },
                    form_key: 'end_date',
                    data_tooltip: false,
                    value_key: 'end_date'
                },
                {
                    name: 'categorySelector',
                    label: 'Category: ',
                    additional_information: undefined,
                    input: {
                        type: 'dropdown'
                    },
                    form_key: 'category',
                    data_tooltip: false,
                    value_key: 'category'
                }
            ],
            currently_selected_run: null,
        }
    },
    methods: {
        showSection(section) {
            // Initialize all variables to false
            const keys = [
                'is_frequency_cap', 
                'is_language', 
                'is_country', 
                'is_imp_constraint', 
                'is_game_constraint', 
                'is_placement_constraint', 
                'is_platform',
                'is_creative',
                'is_game',
                'is_placement',
                'is_disable_rate_limit',
                'is_forecasting', 
                'is_advert_details'
            ];

            keys.forEach(key => {
                this[key] = false;
            });

            // Set the specific variable to true based on the section
            const mapping = {
                'frequency': 'is_frequency_cap',
                'country': 'is_country',
                'language': 'is_language',
                'dates constraint': 'is_imp_constraint',
                'games constraint': 'is_game_constraint',
                'placements constraint': 'is_placement_constraint',
                'platform': 'is_platform',
                'creative': 'is_creative',
                'game': 'is_game',
                'placement': 'is_placement',
                'disable rate limit': 'is_disable_rate_limit',
                'forecasting': 'is_forecasting',
                'ad set detail': 'is_advert_details'
            };

            if (mapping[section]) {
                this[mapping[section]] = true;
            }
        },
        // CAMPAIGN ONLY //
        async addRunToCampaign(run_form) {
            if(!confirm('Are you sure you want to submit this run'))
                return

            run_form.uuid = this.advert_uuid

            const toasts = {'success': 'Ad Set Added to Campaign', 'error': 'Error Adding Ad Set to Campaign'}
            await this.$http_request(this.$http.post, 'runs', toasts, run_form)
                .then( async () => {
                    //refresh campaigns/runs
                    await this.fetchCampaignRuns()
                    this.emitter.emit("refreshCampaignNavbar");

                    this.show_add_new_run = !this.show_add_new_run
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
        inactiveReasons(run){
            return run?.inactive_reasons ?? []
        },
        setCampaignCategory(category) {
            this.advert_form['category'] = category
        },
        //^^ CAMPAIGN ONLY ^^ //
        toggleSelectedRun(run) {
            if(this.currently_selected_run == run) {
                this.showSection('creative')
                this.currently_selected_run = null
            }
            else {
                this.currently_selected_run = run
                this.showSection('ad set detail')

                // scroll to that section
                const settingsSection = document.getElementById("settings-section");
                if (settingsSection) {
                    settingsSection.scrollIntoView({ behavior: "smooth" });
                }
            }
        },
        async getBlameInfo(){
            const blame_info = await this.$store.dispatch('getBlameDetails', this.advert_uuid)
            this.advert.last_modified_by = blame_info.last_modified_by
            this.advert.last_modified_action = blame_info.last_modified_action
        },
        unpackAdvertData(attribute_key){
            let advert_attribute = this.advert[attribute_key];
            if( typeof advert_attribute === 'number') return advert_attribute.toLocaleString()
            else return advert_attribute
        },
        updateAdvert() {
            if(!confirm(`Are You Sure You Want To Update The Campaign With These Values?`))
                return

            const request = {
                advert_type: 'campaigns',
                advert_form: this.advert_form,
            }
            this.$store.dispatch('adverts_module/updateAdvert', request)
                .then((response) => {
                    this.advert = response
                    this.showSection('creative')
                    //refresh campaigns/runs in navbar
                    this.emitter.emit("refreshCampaignNavbar");
                })
                .catch((error) => {
                    console.error(error.message)
                })
            this.switchEditMode()
        },

        async deleteAdvert() {
            if(!confirm(`Are You Sure You Want To Delete The Following Campaign`))
                return

            let uuid = this.advert_uuid
            let request = { 
                advert_type: 'campaigns',
                uuid: uuid,
            }

            await this.$store.dispatch('adverts_module/deleteAdvert', request)
                .catch((error) => {
                    console.error(error.message)
                })
            
            //refresh campaigns/runs in navbar
            this.emitter.emit("refreshCampaignNavbar");

            this.$router.push('/manage-campaigns')
        },
        async deletingRunInfo() {
            this.currently_selected_run = null
            this.showSection('creative')
            await this.fetchCampaignRuns()
        },
        async fetchCampaignRuns(){
            await this.$http.get(`/runs/get-campaign-runs/${this.advert_uuid}`)
                .then(({data}) => {
                    this.campaign_runs = data
            })
        },
        async fetchCampaignRunsFromFrequency(selected_run_info) {
            await this.fetchCampaignRuns()
            
            // Find the run with the same uuid
            const matchingRun = this.campaign_runs.find(run => run.uuid === selected_run_info.uuid);

            // Set currently_selected_run to the matching run
            if (matchingRun) {
                this.currently_selected_run = matchingRun;
            }
        },
        switchEditMode() {
            this.edit_info_card = !this.edit_info_card
        },
    },
    computed: {
        impressionsPercentage() {
            if(!this.advert)
                return 0

            if(this.advert['target_impressions'] == 0)
                return 0

            return Math.floor(this.advert['impressions_sent'] / this.advert['target_impressions'] * 100)
        },
        isRewardVideoComputed: {
            get() {
                return this.advert_form.is_reward_video == 1;
            },
            set(newValue) {
                this.advert_form.is_reward_video = newValue ? 1 : 0;
            }
        },
        dateGapPercentage() {
            if(this.daysInAdvert == 0)
                return 0

            if(!this.advert)
                return 0

            return this.$advertDateGapPercentage(this.advert, 'campaigns', this.user_timezone)
        },
        singularAdvertType() {
            if(this.currently_selected_run != null)
                return 'run'

            return 'campaign'
        }
    },
    async created() { 
        this.advert = await this.$store.dispatch('adverts_module/fetchSingleAdvert', {advert_type: 'campaigns', advert_uuid: this.advert_uuid})
        this.user_timezone = await this.$store.dispatch('login_module/getUserTimezone')
        this.all_categories = this.$store.getters['getCampaignCategories']
        await this.fetchCampaignRuns()
        if(this.campaign_runs.length == 0) 
            this.$notify({type: 'warn', text: 'Please Create Ad Sets To Launch The Campaign', duration: 10000})

        this.advert_form.prePopulate(this.advert)

        await this.getBlameInfo()
    },
}
</script>

<style lang="postcss" scoped>
.options-picker {
    @apply text-sm sm:text-base border border-gray-600 inline-block p-2 lg:p-3 w-32 xl:w-44 bg-white hover:bg-blue-300 hover:cursor-pointer duration-300 text-center;
}

.options-picker-active {
    @apply text-white bg-indigo-500 hover:text-black;
}
</style>
