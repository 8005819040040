<template>
  <div>
    <!-- Survey selection dropdown -->
    <div class="mb-4">
      <label class="block mb-2">Select Survey:</label>
      <select v-model="selectedSurveyUuid" class="border p-2 rounded w-full">
        <option disabled value="">-- Select Survey --</option>
        <option v-for="survey in surveyList" :key="survey.uuid" :value="survey.uuid">
          {{ survey.name }}
        </option>
      </select>
    </div>

    <!-- Date Inputs -->
    <div class="mb-4 flex flex-col sm:flex-row sm:space-x-4">
      <div class="flex-1">
        <label class="block mb-2">Start Date:</label>
        <input type="date" v-model="startDate" class="border p-2 rounded w-full" />
      </div>
      <div class="flex-1">
        <label class="block mb-2">End Date:</label>
        <input type="date" v-model="endDate" class="border p-2 rounded w-full" />
      </div>
    </div>

    <!-- Game Selection (shown only when a survey is selected) -->
    <div v-if="selectedSurveyUuid" class="mb-4">
      <label class="block mb-2">Game(s):</label>
      <DropdownComponent 
          :items="all_survey_games"
          display="name"
          selected="Survey Games"
          @user_select="addGameToFilter"
          class="w-full" />
      <div class="flex flex-row flex-wrap gap-1 mt-2">
        <label 
          v-for="game in game_filter_array" 
          :key="game.uuid" 
          class="bg-white rounded-md px-2 py-1 cursor-pointer hover:bg-blue-300" 
          @click="removeGameFromFilter(game)"
        >
          {{ game.friendly_name }}
        </label>
      </div>
    </div>

    <!-- Generate Report Button -->
    <div class="mb-4">
      <button @click="generateReport" class="bg-blue-500 text-white p-2 rounded">
        Generate Report
      </button>
    </div>

    <!-- Export CSV Button -->
    <div v-if="!loading && reportData.length" class="mb-4">
      <button @click="exportCSV" class="bg-green-500 text-white p-2 rounded">
        Export CSV
      </button>
    </div>

    <!-- Report Table -->
    <div v-if="loading" class="text-center py-4">
      Loading report...
    </div>
    <div v-else-if="reportData.length">
      <table class="min-w-full border-collapse">
        <thead>
          <tr>
            <th class="border p-2">Question/Response</th>
            <th class="border p-2">Responses</th>
            <th class="border p-2">Percentage</th>
            <th class="border p-2">Average Time Spent On Question</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in reportData" :key="index">
            <td class="border p-2" :class="{'text-lg font-bold': row.is_question}">
              {{ row['Question/Response'] }}
            </td>
            <td class="border p-2 text-center">{{ row.Responses }}</td>
            <td class="border p-2 text-center">
              {{ row.is_question ? '-' : row.Percentage }}
            </td>
            <td class="border p-2 text-center">
              {{ row['Average Time Spent On Question'] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <p class="text-center">No report data available.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SurveyReporting",
  data() {
    return {
      surveyList: [],
      selectedSurveyUuid: "",
      startDate: "",
      endDate: "",
      reportData: [],
      loading: false,
      all_survey_games: [],
      game_filter_array: [],
    };
  },
  created() {
    this.fetchSurveyList();
  },
  watch: {
    selectedSurveyUuid(newVal) {
      // Clear game filters and available games when survey changes.
      this.all_survey_games = [];
      this.game_filter_array = [];
      if (newVal) {
        this.fetchSurveyGames();
      }
    }
  },
  methods: {
    async fetchSurveyList() {
      try {
        const response = await this.$http.get("/surveys");
        this.surveyList = response.data;
        console.log("Survey list:", this.surveyList);
      } catch (error) {
        console.error("Error fetching survey list:", error);
      }
    },
    async fetchSurveyGames() {
      try {
        const response = await this.$http.get(
          `/surveys/survey-games/${this.selectedSurveyUuid}`,
          { headers: { 'Accept': 'application/json' } }
        );
        if (Array.isArray(response.data)) {
          this.all_survey_games = response.data;
        } else {
          console.error("Unexpected response format for survey games:", response.data);
          this.all_survey_games = [];
        }
        console.log("Survey games:", this.all_survey_games);
      } catch (error) {
        console.error("Error fetching survey games:", error);
      }
    },
    addGameToFilter(game) {
      if (!this.game_filter_array.some(g => g.uuid === game.uuid)) {
        this.game_filter_array.push(game);
        this.all_survey_games = this.all_survey_games.filter(el => el.uuid !== game.uuid);
      }
    },
    removeGameFromFilter(game) {
      this.game_filter_array = this.game_filter_array.filter(g => g.uuid !== game.uuid);
      this.all_survey_games.push(game);
      this.all_survey_games.sort((a, b) => a.name.localeCompare(b.name));
    },
    async generateReport() {
      if (!this.selectedSurveyUuid) {
        alert("Please select a survey.");
        return;
      }
      this.loading = true;
      try {
        const params = { survey_uuid: this.selectedSurveyUuid };
        if (this.startDate) params.start_date = this.startDate;
        if (this.endDate) params.end_date = this.endDate;
        if (this.game_filter_array.length) {
          params.game_place_ids = this.game_filter_array.map(game => game.place_id);
        }
        const response = await this.$http.get("/surveys/reports/basic-report", { params });
        this.reportData = response.data;
      } catch (error) {
        console.error("Error fetching survey report:", error);
      } finally {
        this.loading = false;
      }
    },
    exportCSV() {
      if (!this.reportData || !this.reportData.length) return;
      const headers = [
        "Question/Response",
        "Responses",
        "Percentage",
        "Average Time Spent On Question"
      ];
      let csv = headers.join(",") + "\n";
      this.reportData.forEach(row => {
        const rowData = headers.map(header => {
          let cell = row[header];
          if (cell === null || cell === undefined) cell = "";
          if (typeof cell === "string") {
            cell = cell.replace(/"/g, '""');
            return `"${cell}"`;
          }
          return cell;
        });
        csv += rowData.join(",") + "\n";
      });
      const survey = this.surveyList.find(s => s.uuid === this.selectedSurveyUuid);
      const surveyName = survey ? survey.name.replace(/\s+/g, '-') : "Unknown-Survey";
      const filenameParts = [`Survey-Report`, surveyName];
      if (this.startDate) filenameParts.push(this.startDate);
      if (this.endDate) filenameParts.push(this.endDate);
      // Append selected game names (if any) joined by hyphens.
      if (this.game_filter_array.length) {
        const gameNames = this.game_filter_array
          .map(game => game.friendly_name.replace(/\s+/g, '-'))
          .join('-');
        filenameParts.push(gameNames);
      }
      const filename = `${filenameParts.join('-')}.csv`;
      const link = document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csv));
      link.setAttribute("download", filename);
      link.click();
    }
  },
};
</script>

<style scoped>
table {
  border: 1px solid #ddd;
}
th,
td {
  border: 1px solid #ddd;
}
</style>
