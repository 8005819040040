<template>
  <div class="container mx-auto p-6 relative">
    <h1 class="text-2xl font-bold mb-4">Manage Survey</h1>

    <button @click="openCreateSurveyModal" class="mb-4 p-2 bg-blue-500 text-white rounded">
      + Create New Survey
    </button>

    <!-- Auto Balance Intervals Button -->
    <button
      @click="openBalanceIntervalsModal"
      class="p-2 bg-yellow-500 text-white rounded mt-2"
      :disabled="balancing"
    >
      {{ balancing ? 'Balancing...' : 'Auto Balance Question Set Intervals' }}
    </button>

    <!-- Balance Intervals Modal -->
    <div v-if="showBalanceIntervalsModal" class="mt-4">
      <label class="block text-sm font-semibold mb-2">
        Days each question set is visible:
      </label>
      <input
        type="number"
        v-model.number="questionSetVisibilityDays"
        min="1"
        class="p-2 border rounded w-24"
      />
      <button
        @click="balanceIntervals"
        class="p-2 bg-blue-500 text-white rounded mt-2"
        :disabled="balancing"
      >
        Confirm
      </button>
      <button
        @click="showBalanceIntervalsModal = false"
        class="p-2 ml-2 bg-gray-300 text-gray-700 rounded mt-2"
      >
        Cancel
      </button>
    </div>

    <SurveyDropdown @survey-selected="onSurveySelected" />

    <!-- Combined loading indicator -->
    <div v-if="isLoading" class="flex flex-col gap-2 justify-center items-center h-full">
      <h2 v-if="loading">Fetching data... This might take a few minutes.</h2>
      <h2 v-else-if="balancing">Balancing intervals...</h2>
      <LoadingComponent />
    </div>

    <!-- Main Content -->
    <div v-if="!isLoading">
      <div class="mt-4" v-if="selectedSurvey">
        <!-- Delete Survey -->
        <button
          @click="deleteSelectedSurvey"
          class="p-2 bg-red-500 text-white rounded mr-2"
        >
          Delete Survey
        </button>
      </div>

      <!-- Survey Details -->
      <SurveyDetails v-if="selectedSurvey" :survey="selectedSurvey" />

      <!-- NPC Management Section -->
      <div v-if="selectedSurvey" class="mt-6">
        <h2 class="text-xl font-semibold mb-2">
          Manage NPCs for {{ selectedSurvey.name }}
        </h2>

        <!-- NPC dropdown + Link button -->
        <div class="mb-4">
          <label class="block mb-2">Select an NPC to link:</label>
          <select v-model="selectedNpcUuid" class="p-2 border rounded w-64">
            <option disabled value="">-- Select NPC --</option>
            <option v-for="npc in npcs" :key="npc.uuid" :value="npc.uuid">
              {{ npc.name }} ({{ npc.uuid }})
            </option>
          </select>
          <button
            @click="linkNpc"
            class="p-2 bg-blue-500 text-white rounded ml-2"
            :disabled="!selectedNpcUuid"
          >
            Link NPC
          </button>
        </div>

        <!-- Currently Linked NPCs -->
        <div>
          <h3 class="font-semibold mb-2">Linked NPCs</h3>
          <div v-if="linkedNpcs.length">
            <div
              v-for="npc in linkedNpcs"
              :key="npc.uuid"
              class="flex items-center justify-between mb-2 p-2 border rounded"
            >
              <span>{{ npc.name }} ({{ npc.uuid }})</span>
              <button
                @click="unlinkNpc(npc.uuid)"
                class="bg-red-500 text-white py-1 px-2 rounded"
              >
                Unlink
              </button>
            </div>
          </div>
          <p v-else class="text-sm text-gray-700">
            No NPCs linked yet.
          </p>
        </div>
      </div>

      <!-- Intervals Calendar -->
      <div v-if="selectedSurvey" class="mt-4">
        <button
          @click="toggleIntervalsCalendar"
          class="p-2 bg-gray-500 text-white rounded"
        >
          {{ showIntervalsCalendar ? 'Hide Intervals Calendar' : 'Show Intervals Calendar' }}
        </button>
        <div v-if="showIntervalsCalendar" class="mt-4">
          <SurveyIntervalsCalendar
            :surveyUuid="selectedSurvey.uuid"
            :surveyStartDate="selectedSurvey.start_datetime"
            :surveyEndDate="selectedSurvey.end_datetime"
            :questionSets="formattedQuestionSets"
          />
        </div>
      </div>

      <!-- Question Sets Section -->
      <div v-if="selectedSurvey" class="mt-6">
        <h2 class="text-xl font-semibold mb-2">Question Sets</h2>

        <!-- Skeleton Placeholder -->
        <div v-if="loadingQuestionSets" class="space-y-4">
          <div
            v-for="n in questionSetsSkeletonPlaceholdersCount"
            :key="n"
            class="animate-pulse bg-gray-200 rounded h-20"
          ></div>
        </div>

        <!-- List of Question Sets -->
        <div v-else-if="selectedSurvey.question_sets?.length">
          <div
            v-for="set in selectedSurvey.question_sets"
            :key="set.uuid"
            class="mb-4"
          >
            <QuestionSet
              :questionSet="set"
              :surveyUuid="selectedSurvey.uuid"
              :surveyStartDate="selectedSurvey.start_datetime"
              :surveyEndDate="selectedSurvey.end_datetime"
              :questionSetVisibilityDays="questionSetVisibilityDays"
            />
          </div>
        </div>
        <!-- Message when no question sets -->
        <div v-else>
          <p>No question sets available.</p>
        </div>

        <!-- "Add Question Set" Button -->
        <button
          @click="openCreateQuestionSetModal"
          class="p-2 bg-green-500 text-white rounded"
        >
          + Add Question Set
        </button>
      </div>

      <!-- Create Modals -->
      <CreateSurvey
        v-if="showCreateSurveyModal"
        @close="showCreateSurveyModal = false"
        @survey-created="fetchSurveys"
      />
      <CreateQuestionSet
        v-if="showCreateQuestionSetModal"
        :surveyId="selectedSurvey?.uuid"
        @close="showCreateQuestionSetModal = false"
      />

      <!-- CSV Upload Section -->
      <div class="mt-6 bg-white p-4 rounded shadow-md">
        <h2 class="text-lg font-semibold mb-2">Upload Survey via CSV</h2>
        <input type="file" accept=".csv" @change="handleFileChange" class="mb-2" />
        <button
          v-if="csvFile"
          @click="uploadCsv"
          class="p-2 bg-blue-500 text-white rounded"
          :disabled="uploadingCsv"
        >
          Upload CSV
        </button>
        <p v-if="uploadMessage" class="mt-2 text-sm">{{ uploadMessage }}</p>
      </div>
    </div>

    <!-- Overlay for uploading CSV -->
    <div
      v-if="uploadingCsv"
      class="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50"
    >
      <div class="flex flex-col items-center">
        <LoadingComponent />
        <p class="text-white mt-2">Uploading CSV, please wait...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SurveyDetails from '@/components/Survey/SurveyDetails.vue';
import QuestionSet from '@/components/Survey/QuestionSet.vue';
import SurveyDropdown from '@/components/Survey/SurveyDropdown.vue';
import CreateSurvey from '@/components/Survey/CreateSurvey.vue';
import CreateQuestionSet from '@/components/Survey/CreateQuestionSet.vue';
import SurveyIntervalsCalendar from '@/components/Survey/SurveyIntervalsCalendar.vue';

export default {
  name: 'ManageSurveys',
  components: {
    SurveyDetails,
    QuestionSet,
    SurveyDropdown,
    CreateSurvey,
    CreateQuestionSet,
    SurveyIntervalsCalendar,
  },
  data() {
    return {
      questionSetVisibilityDays: 1,
      showCreateSurveyModal: false,
      showCreateQuestionSetModal: false,
      showBalanceIntervalsModal: false,
      showIntervalsCalendar: false,
      selectedSurveyId: null,
      csvFile: null,
      uploadMessage: '',
      uploadingCsv: false,
      loadingQuestionSets: false,
      selectedNpcUuid: ''
    };
  },
  computed: {
    ...mapState('surveys', {
      surveysList: 'surveys',
      loading: 'loading',
      balancing: 'balancing',
    }),
    ...mapState('npcs', {
      npcs: 'npcs',
      npcsLoading: 'loading',
    }),
    isLoading() {
      return this.loading || this.balancing || this.npcsLoading;
    },
    selectedSurvey() {
      if (!this.selectedSurveyId) return null;
      return this.$store.getters['surveys/getSurveyById'](this.selectedSurveyId);
    },
    // Return the linked NPCs directly from the survey
    linkedNpcs() {
      return this.selectedSurvey && this.selectedSurvey.npcs
        ? this.selectedSurvey.npcs
        : [];
    },
    questionSetsSkeletonPlaceholdersCount() {
      return this.selectedSurvey?.question_sets?.length || 3;
    },
    formattedQuestionSets() {
      return this.selectedSurvey?.question_sets || [];
    },
  },
  methods: {
    ...mapActions('surveys', [
      'fetchSurveys',
      'fetchSurvey',
      'balanceSurveyIntervals',
      'deleteSurvey',
    ]),
    ...mapActions('npcs', [
      'fetchNpcs',
      'linkNpcToSurveys',
      'unlinkNpcFromSurveys',
    ]),
    openCreateSurveyModal() {
      this.showCreateSurveyModal = true;
    },
    openCreateQuestionSetModal() {
      this.showCreateQuestionSetModal = true;
    },
    openBalanceIntervalsModal() {
      this.showBalanceIntervalsModal = true;
    },
    async onSurveySelected(surveyId) {
      this.selectedSurveyId = surveyId;
      this.loadingQuestionSets = true;
      await this.fetchSurvey(surveyId);
      this.loadingQuestionSets = false;
    },
    async balanceIntervals() {
      await this.balanceSurveyIntervals({
        surveyId: this.selectedSurveyId,
        questionSetVisibilityDays: this.questionSetVisibilityDays,
      });
      this.showBalanceIntervalsModal = false;
    },
    toggleIntervalsCalendar() {
      this.showIntervalsCalendar = !this.showIntervalsCalendar;
    },
    async deleteSelectedSurvey() {
      if (!this.selectedSurveyId) return;
      const confirmed = confirm(
        'Are you sure you want to delete this survey? This action cannot be undone.'
      );
      if (confirmed) {
        await this.deleteSurvey(this.selectedSurveyId);
        this.selectedSurveyId = null;
      }
    },
    handleFileChange(event) {
      this.csvFile = event.target.files[0];
    },
    async uploadCsv() {
      if (!this.csvFile) return;
      try {
        this.uploadingCsv = true;
        const formData = new FormData();
        formData.append('file', this.csvFile);
        await this.$http.post('/surveys/upload-csv', formData);
        await this.fetchSurveys();
      } finally {
        this.uploadingCsv = false;
      }
    },
    // Link NPC using the Vuex action and then re-fetch the survey to update linked NPCs
    async linkNpc() {
      if (!this.selectedNpcUuid || !this.selectedSurvey) return;
      try {
        await this.linkNpcToSurveys({
          npcUuid: this.selectedNpcUuid,
          surveyUuids: [this.selectedSurvey.uuid],
        });
        await this.fetchSurvey(this.selectedSurvey.uuid);
        this.selectedNpcUuid = '';
      } catch (error) {
        console.error('Failed to link NPC:', error);
      }
    },
    // Unlink NPC and then re-fetch the survey
    async unlinkNpc(npcUuid) {
      if (!npcUuid || !this.selectedSurvey) return;
      try {
        await this.unlinkNpcFromSurveys({
          npcUuid,
          surveyUuids: [this.selectedSurvey.uuid],
        });
        await this.fetchSurvey(this.selectedSurvey.uuid);
      } catch (error) {
        console.error('Failed to unlink NPC:', error);
      }
    },
  },
  created() {
    this.fetchSurveys();
    this.fetchNpcs();
  },
};
</script>

<style scoped>
.animate-pulse {
  animation: pulse 1.5s ease-in-out infinite;
}
</style>
