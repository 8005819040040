<template>
    <div>
        <div v-if="loading" class="flex items-center justify-center">
            <LoadingComponent />
        </div>
        <div
            v-else
            class="bg-white p-4 rounded-lg shadow-md mb-4 border-2 border-solid"
            :style="{ borderColor: questionSetColor }"
        >
            <!-- Row container for Order and Buttons -->
            <div class="flex items-center gap-2 mb-4">
                <label for="orderInput" class="text-sm text-gray-700">Order:</label>
                <input
                    id="orderInput"
                    type="number"
                    class="p-1 border rounded w-16"
                    v-model.number="editableQuestionSet.order"
                    @change="saveOrder"
                />
                <button @click="toggleIntervals" class="p-1 bg-gray-500 text-white rounded">
                    {{ showIntervals ? 'Hide Intervals' : 'Show Intervals' }}
                </button>
                <button @click="addInterval" class="p-1 bg-green-500 text-white rounded">
                    + Add Interval
                </button>
                <button @click="saveIntervals" class="p-1 bg-blue-500 text-white rounded">
                    Save Intervals
                </button>
            </div>

            <!-- Intervals Section -->
            <div v-if="showIntervals">
                <h4 class="text-md font-semibold mt-4">Visibility Intervals</h4>
                <div v-for="(interval, index) in editableIntervals" :key="index" class="mb-2 flex gap-2">
                    <label class="block text-sm text-gray-500">
                        Start:
                        <input
                            type="datetime-local"
                            v-model="interval.start_datetime"
                            :min="surveyStartDate"
                            :max="surveyEndDate"
                            @change="validateInterval(index)"
                            class="p-2 border rounded w-full"
                        />
                    </label>
                    <label class="block text-sm text-gray-500">
                        End:
                        <input
                            type="datetime-local"
                            v-model="interval.end_datetime"
                            :min="interval.start_datetime"
                            :max="surveyEndDate"
                            @change="validateInterval(index)"
                            class="p-2 border rounded w-full"
                        />
                    </label>
                    <button @click="removeInterval(index)" class="text-red-500 hover:text-red-700">
                        Remove
                    </button>
                </div>
            </div>

            <!-- Questions Section -->
            <div v-if="questionSet.questions" class="mt-4">
                <h4 class="text-md font-semibold mb-2">Questions</h4>
                <div v-for="question in orderedQuestions" :key="question.uuid" class="mb-2">
                    <Question
                        :question="question"
                        :questionSetId="questionSet.uuid"
                        :surveyId="surveyUuid"
                    />
                </div>
                <button @click="openCreateQuestionModal" class="p-2 bg-blue-500 text-white rounded mt-2">
                    + Add Question
                </button>
            </div>

            <!-- Delete Button -->
            <button
                @click="handleDeleteQuestionSet"
                class="p-2 bg-red-500 text-white rounded mt-2"
            >
                Delete Question Set
            </button>

            <!-- Create Question Modal -->
            <CreateQuestion
                v-if="showCreateQuestionModal"
                :questionSetId="questionSet.uuid"
                :surveyUuid="surveyUuid"
                @close="showCreateQuestionModal = false"
            />
        </div>
    </div>
</template>

<script>
import Question from '@/components/Survey/QuestionComponent.vue';
import CreateQuestion from '@/components/Survey/CreateQuestion.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: { Question, CreateQuestion },
    props: {
        questionSet: { type: Object, required: true },
        surveyUuid: { type: String, required: true },
        surveyStartDate: { type: String, required: true },
        surveyEndDate: { type: String, required: true },
        questionSetVisibilityDays: { type: Number, required: true, default: 1 },
    },
    data() {
        return {
            editableQuestionSet: { ...this.questionSet },
            editableIntervals: this.formatIntervals(this.questionSet.intervals || []),
            showCreateQuestionModal: false,
            showIntervals: false,
            loading: false,
        };
    },
    computed: {
        ...mapGetters('surveys', ['getQuestionSetColor']),
        orderedQuestions() {
            let questions = this.questionSet.questions || [];
            return questions.sort((a, b) => a.order - b.order);
        },
        questionSetColor() {
            return this.getQuestionSetColor(this.questionSet.uuid);
        },
    },
    methods: {
        ...mapActions('questionSets', ['deleteQuestionSet', 'updateQuestionSet']),

        // Save the updated order
        async saveOrder() {
            try {
                this.loading = true;
                const updatedQuestionSet = {
                    ...this.questionSet,
                    order: this.editableQuestionSet.order,
                };
                // Send an update to the Vuex store
                await this.updateQuestionSet({
                    surveyId: this.surveyUuid,
                    updatedQuestionSet,
                });
            } catch (error) {
                console.error('Failed to update order:', error);
            } finally {
                this.loading = false;
            }
        },

        // Existing intervals logic
        formatIntervals(intervals) {
            if (!Array.isArray(intervals)) return [];
            return intervals.map(interval => ({
                ...interval,
                start_datetime: interval.start_datetime.slice(0, 16),
                end_datetime: interval.end_datetime.slice(0, 16),
            }));
        },
        addInterval() {
            const newStart = new Date(this.surveyStartDate);
            const newEnd = new Date(newStart);
            newEnd.setDate(newEnd.getDate() + this.questionSetVisibilityDays);

            this.editableIntervals.push({
                start_datetime: newStart.toISOString().slice(0, 16),
                end_datetime: newEnd.toISOString().slice(0, 16),
            });
        },
        removeInterval(index) {
            this.editableIntervals.splice(index, 1);
        },
        toggleIntervals() {
            this.showIntervals = !this.showIntervals;
        },
        async saveIntervals() {
            this.loading = true;
            try {
                await this.$http.post(`/question-sets/${this.questionSet.uuid}/intervals`, {
                    question_set_uuid: this.questionSet.uuid,
                    intervals: this.editableIntervals,
                });
                this.$emit('question-set-updated', {
                    ...this.questionSet,
                    intervals: this.editableIntervals,
                });
            } catch (error) {
                console.error('Error saving intervals:', error);
            } finally {
                this.loading = false;
            }
        },
        validateInterval(index) {
            const interval = this.editableIntervals[index];
            const start = new Date(interval.start_datetime);
            const end = new Date(interval.end_datetime);

            if (start < new Date(this.surveyStartDate)) {
                this.editableIntervals[index].start_datetime = this.surveyStartDate;
            }
            if (end > new Date(this.surveyEndDate)) {
                this.editableIntervals[index].end_datetime = this.surveyEndDate;
            }
        },
        async handleDeleteQuestionSet() {
            const confirmed = confirm(
                'Are you sure you want to delete this question set? This action cannot be undone.'
            );
            if (confirmed) {
                try {
                    await this.deleteQuestionSet({
                        surveyId: this.surveyUuid,
                        questionSetId: this.questionSet.uuid,
                    });
                    this.$emit('question-set-deleted', this.questionSet.uuid);
                } catch (error) {
                    console.error('Error deleting question set:', error);
                }
            }
        },
        openCreateQuestionModal() {
            this.showCreateQuestionModal = true;
        },
    },
    watch: {
        questionSet: {
            handler(newQuestionSet) {
                this.editableQuestionSet = { ...newQuestionSet };
                this.editableIntervals = this.formatIntervals(newQuestionSet.intervals || []);
            },
            deep: true,
        },
    },
};
</script>
