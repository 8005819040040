// store/SurveySystemSurveysModule.js
import axios from 'axios';
import { notify } from '@kyvg/vue3-notification';
import { deepEqual } from '@/utils/DataCompare';

const state = {
  surveys: [],
  loading: false,
  balancing: false,
  questionSetVisibilityDays: 1,
  questionSetColors: {}, // Add a map for questionSet UUID -> color class
};

const mutations = {
  DO_NOTHING() {},
  SET_SURVEYS(state, surveys) {
    state.surveys = surveys;
    generateQuestionSetColors(state);
  },
  ADD_SURVEY(state, survey) {
    survey.question_sets = survey.question_sets || [];
    state.surveys.push(survey);
  },
  UPDATE_SURVEY(state, updatedSurvey) {
    const index = state.surveys.findIndex((s) => s.uuid === updatedSurvey.uuid);
    if (index !== -1) {
      updatedSurvey.question_sets = updatedSurvey.question_sets || [];
      state.surveys.splice(index, 1, updatedSurvey);
    } else {
      updatedSurvey.question_sets = updatedSurvey.question_sets || [];
      state.surveys.push(updatedSurvey);
    }
    generateQuestionSetColors(state); // Re-generate colors after update
  },
  DELETE_SURVEY(state, surveyId) {
    state.surveys = state.surveys.filter(s => s.uuid !== surveyId);
  },

  SET_NPCS(state, npcs) {
    state.npcs = npcs;
  },

  // Mutations for nested entities
  ADD_QUESTION_SET(state, { surveyId, questionSet }) {
    const survey = state.surveys.find((s) => s.uuid === surveyId);
    if (!survey) return;

    questionSet.questions = questionSet.questions || [];
    survey.question_sets.push(questionSet);
    generateQuestionSetColors(state);
  },
  UPDATE_QUESTION_SET(state, { surveyId, questionSet }) {
    const survey = state.surveys.find((s) => s.uuid === surveyId);
    if (!survey) return;

    const index = survey.question_sets.findIndex(
      (qs) => qs.uuid === questionSet.tempUuid || qs.uuid === questionSet.uuid
    );
    if (index !== -1) {
      questionSet.questions = questionSet.questions || [];
      delete questionSet.tempUuid;
      survey.question_sets.splice(index, 1, questionSet);
    } else {
      questionSet.questions = questionSet.questions || [];
      survey.question_sets.push(questionSet);
    }
    generateQuestionSetColors(state);
  },
  DELETE_QUESTION_SET(state, { surveyId, questionSetId }) {
    const survey = state.surveys.find((s) => s.uuid === surveyId);
    if (!survey) return;

    survey.question_sets = survey.question_sets.filter((qs) => qs.uuid !== questionSetId);
    // After deletion, we can clean up colors if needed:
    delete state.questionSetColors[questionSetId];
  },
  
  ADD_QUESTION(state, { surveyId, questionSetId, question }) {
    const survey = state.surveys.find((s) => s.uuid === surveyId);
    if (survey) {
      const questionSet = survey.question_sets.find((qs) => qs.uuid === questionSetId);
      if (questionSet) {
        if (!question.responses) {
          question.responses = [];
        }
        questionSet.questions = [...questionSet.questions, question];
      }
    }
  },
  
  UPDATE_QUESTION(state, { surveyId, questionSetId, question }) {
    const survey = state.surveys.find((s) => s.uuid === surveyId);
    if (survey) {
      const questionSet = survey.question_sets.find((qs) => qs.uuid === questionSetId);
      if (questionSet) {
        const index = questionSet.questions.findIndex(
          (q) => q.uuid === question.tempUuid || q.uuid === question.uuid
        );
        if (index !== -1) {
          if (!question.responses) {
            question.responses = [];
          }
          questionSet.questions.splice(index, 1, question);
        } else {
          question.responses = question.responses || [];
          questionSet.questions.push(question);
        }
      }
    }
  },
  
  DELETE_QUESTION(state, { surveyId, questionSetId, questionId }) {
    const survey = state.surveys.find((s) => s.uuid === surveyId);
    if (survey) {
      const questionSet = survey.question_sets.find((qs) => qs.uuid === questionSetId);
      if (questionSet) {
        questionSet.questions = questionSet.questions.filter((q) => q.uuid !== questionId);
      }
    }
  },

  ADD_RESPONSE(state, { surveyId, questionSetId, questionId, response }) {
    const survey = state.surveys.find((s) => s.uuid === surveyId);
    if (survey) {
      const questionSet = survey.question_sets.find((qs) => qs.uuid === questionSetId);
      if (questionSet) {
        const question = questionSet.questions.find((q) => q.uuid === questionId);
        if (question) {
          question.responses.push(response);
        }
      }
    }
  },

  UPDATE_RESPONSE(state, { surveyId, questionSetId, questionId, response }) {
    const survey = state.surveys.find(s => s.uuid === surveyId);
    if (survey) {
      const questionSet = survey.question_sets.find(qs => qs.uuid === questionSetId);
      if (questionSet) {
        const question = questionSet.questions.find(q => q.uuid === questionId);
        if (question) {
          const index = question.responses.findIndex(
            r => r.uuid === response.tempUuid || r.uuid === response.uuid
          );
          if (index !== -1) {
            question.responses.splice(index, 1, response);
          } else {
            question.responses.push(response);
          }
        }
      }
    }
  },

  DELETE_RESPONSE(state, { surveyId, questionSetId, questionId, responseId }) {
    const survey = state.surveys.find(s => s.uuid === surveyId);
    if (survey) {
      const questionSet = survey.question_sets.find(qs => qs.uuid === questionSetId);
      if (questionSet) {
        const question = questionSet.questions.find(q => q.uuid === questionId);
        if (question) {
          question.responses = question.responses.filter(r => r.uuid !== responseId);
        }
      }
    }
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_BALANCING(state, isBalancing) {
    state.balancing = isBalancing;
  },
};

const actions = {
  async fetchSurveys({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await axios.get('/surveys');
      console.log('Response data:', response.data); // Add this line for debugging
      if (response.data && Array.isArray(response.data)) {
        commit('SET_SURVEYS', response.data);
      } else {
        console.error('Invalid response data:', response.data);
        commit('SET_SURVEYS', []);
      }
    } catch (error) {
      console.error('Error fetching surveys:', error);
      commit('SET_SURVEYS', []);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchSurvey({ commit }, surveyId) {
    try {
      const response = await axios.get(`/surveys/${surveyId}`, {
        params: { with: 'questionSets.questions.responses,questionSets.intervals,npcs' },
      });
      if (response.data && response.data.data) {
        commit('UPDATE_SURVEY', response.data.data);
      } else {
        console.error('Invalid response data:', response.data);
      }
    } catch (error) {
      console.error('Error fetching survey:', error);
    }
  },
  async createSurvey({ commit }, newSurvey) {
    commit('ADD_SURVEY', newSurvey); // Optimistic update
    try {
      const response = await axios.post('/surveys', newSurvey);
      commit('UPDATE_SURVEY', response.data);
      notify({ title: 'Success', text: 'Survey created', type: 'success' });
    } catch (error) {
      commit('DELETE_SURVEY', newSurvey.uuid);
      notify({ title: 'Error', text: 'Failed to create survey', type: 'error' });
    }
  },  
  async updateSurvey({ commit, state }, updatedSurvey) {
    commit('SET_LOADING', true);
    try {
      const originalSurvey = state.surveys.find(s => s.uuid === updatedSurvey.uuid);
      if (!originalSurvey) {
        throw new Error('Survey not found');
      }

      // Check for changes before making the request
      if (deepEqual(originalSurvey, updatedSurvey)) {
        // No changes detected
        commit('SET_LOADING', false);
        return;
      }

      // Proceed if changed
      await axios.put(`/surveys/${updatedSurvey.uuid}`, updatedSurvey);
      commit('UPDATE_SURVEY', updatedSurvey);
      notify({ title: 'Success', text: 'Survey updated', type: 'success' });

      // ... logic for balancing intervals if needed
    } catch (error) {
      console.error('Error updating survey:', error);
      notify({ title: 'Error', text: 'Failed to update survey', type: 'error' });
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteSurvey({ commit, state }, surveyId) {
    const surveyToDelete = state.surveys.find(s => s.uuid === surveyId);
    if (!surveyToDelete) return;
    commit('DELETE_SURVEY', surveyId); // Optimistic update
    try {
      await axios.delete(`/surveys/${surveyId}`);
      notify({ title: 'Success', text: 'Survey deleted', type: 'success' });
    } catch (error) {
      commit('ADD_SURVEY', surveyToDelete);
      notify({ title: 'Error', text: 'Failed to delete survey', type: 'error' });
    }
  },
  async balanceSurveyIntervals({ commit, dispatch }, { surveyId, questionSetVisibilityDays }) {
    commit('SET_BALANCING', true);
    try {
      await axios.put(`/surveys/${surveyId}/balance-intervals`, { questionSetVisibilityDays });
      notify({ title: 'Success', text: 'Intervals balanced', type: 'success' });
      await dispatch('fetchSurvey', surveyId);
    } catch (error) {
      console.error('Error balancing intervals:', error);
      notify({ title: 'Error', text: 'Failed to balance intervals', type: 'error' });
    } finally {
      commit('SET_BALANCING', false);
    }
  },
  getSurveyById: (state) => (id) => {
    return state.surveys.find((survey) => survey.uuid === id);
  },
  getQuestionSetById: (state) => (questionSetId) => {
    for (const survey of state.surveys) {
      const questionSet = survey.question_sets.find((qs) => qs.uuid === questionSetId);
      if (questionSet) {
        return questionSet;
      }
    }
    return null;
  },
  // --- NPC actions ---
  async fetchNpcs({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await axios.get('/npcs');
      commit('SET_NPCS', response.data.data ?? response.data);
    } catch (error) {
      console.error('Error fetching NPCs:', error);
      commit('SET_NPCS', []);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

const getters = {
  getSurveyById: (state) => (id) => {
    return state.surveys.find(survey => survey.uuid === id);
  },
  getQuestionSetById: (state) => (surveyId, questionSetId) => {
    const survey = state.surveys.find(s => s.uuid === surveyId);
    if (survey) {
      return survey.question_sets.find(qs => qs.uuid === questionSetId);
    }
    return null;
  },
  getQuestionById: (state) => (surveyId, questionSetId, questionId) => {
    const questionSet = state.surveys
      .find(survey => survey.uuid === surveyId)?.question_sets
      .find(qs => qs.uuid === questionSetId);
    if (questionSet) {
      return questionSet.questions.find(q => q.uuid === questionId);
    }
    return null;
  },

  getNpcById: (state) => (id) => {
    return state.npcs.find(npc => npc.uuid === id);
  },

  getQuestionSetColor: (state) => (questionSetId) => {
    return state.questionSetColors[questionSetId] || 'bg-gray-500';
  },
};

function generateQuestionSetColors(state) {
  state.questionSetColors = {}; // Reset before assigning

  const totalQuestionSets = state.surveys.reduce(
    (count, survey) => count + survey.question_sets.length,
    0
  );

  const GOLDEN_RATIO = 0.61803398875;
  const saturation = 70; // Maintain consistent saturation for vibrant colors
  const lightness = 50;  // Balanced lightness for visibility

  const hues = [];
  let hue = Math.random() * 360; // Start with a random base hue

  // Generate hues using the Golden Ratio to space them out
  for (let i = 0; i < totalQuestionSets; i++) {
    hues.push(hue % 360);
    hue += 360 * GOLDEN_RATIO; // Increment hue by golden ratio scaled to full circle
  }

  // Shuffle hues to ensure far-apart colors for adjacent question sets
  const shuffledHues = interleaveArray(hues);

  let colorIndex = 0;
  state.surveys.forEach((survey) => {
    survey.question_sets.forEach((qs) => {
      const currentHue = shuffledHues[colorIndex % totalQuestionSets];
      state.questionSetColors[qs.uuid] = `hsl(${currentHue}, ${saturation}%, ${lightness}%)`;
      colorIndex++;
    });
  });
}

// Utility to interleave an array to maximize distance between adjacent values
function interleaveArray(arr) {
  const mid = Math.ceil(arr.length / 2);
  const firstHalf = arr.slice(0, mid);
  const secondHalf = arr.slice(mid);
  const result = [];

  for (let i = 0; i < mid; i++) {
    result.push(firstHalf[i]);
    if (secondHalf[i] !== undefined) result.push(secondHalf[i]);
  }

  return result;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
