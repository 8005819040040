/* CREATE */
import CreateCampaign from '../views/Create/CreateCampaign.vue'
import CreateGif from '../views/Create/CreateGif.vue'
import CreateVideo from '../views/Create/CreateVideo.vue'
import CreateVideoToRoblox from '../views/Create/CreateVideoToRoblox.vue'
import CreatePlacement from '../views/Create/CreatePlacement.vue'
import CreateCreative from '../views/Create/CreateCreative.vue'

/* MANAGE */
import ManageGames from '../views/Manage/ManageGames.vue'
import ManageCampaigns from '../views/Manage/ManageCampaigns.vue'
import ManageAdvert from '../views/Manage/ManageAdvert.vue'
import ManageItem from '../views/Manage/ManageItem.vue'
import ManageAllItems from '../views/Manage/ManageAllItems.vue'
import ManageArchive from '../views/Manage/ManageArchive.vue'

/* OTHER */
import Overview from '../views/Overview.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import DynamicParameters from '../views/DynamicParameters.vue'
import ChangeHistory from '../views/ChangeHistory.vue'
import UserModeration from '../views/User/UserModeration.vue'
import UserPermissions from '../views/User/UserPermissions.vue'
import CreateUser from '../views/User/CreateUser.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Dialog from '../views/DialogPage.vue'
import NativeDisplayReporting from '../views/NativeDisplayReporting.vue'
import DialogMaker from '../views/DialogMaker/DialogMaker.vue'
import GamefamGameStats from '../views/GamefamGameStats.vue'
import VideoImpressionsReporting from '../views/VideoImpressionsReporting.vue'
import IabRequirements from '../views/IabRequirements.vue'
import HistoricDashboard from '../views/HistoricDashboard.vue'
import DailyGameReporting from '../views/DailyGameReporting.vue'
import UserSettings from '../views/UserSettings.vue'
import SidebarEditor from '../views/SidebarEditor.vue'
import IntegrationsDashboard from '../views/IntegrationsDashboard.vue'
import ManageIntegrations from '../views/Manage/ManageIntegrations.vue'
import CombinedReporting from '../views/CombinedReporting.vue'
import ManageIntegrationGroups from '../views/Manage/ManageIntegrationGroups.vue'
import ManageCampaignPrimers from '../views/Manage/ManageCampaignPrimers.vue'
import FortnitePortfolioMetrics from '../views/FortnitePortfolioMetrics.vue'
import ManageFortniteIntegrations from '../views/Manage/ManageFortniteIntegrations.vue'
import FortniteIntegrationsReporting from '../views/FortniteIntegrationReporting.vue'
import ManageSurveys from '../views/Manage/ManageSurveys.vue'
import CreateNpc from '../views/Create/CreateNpc.vue'
import GamefamPortfolioMetrics from '../views/GamefamPortfolioMetrics.vue'
import SurveyReporting from '../views/SurveyReporting.vue'
import PlayerMetricsWhitelist from '../views/PlayerMetricsWhitelist.vue'
import ManageNewIntegrations from '../views/Manage/ManageNewIntegrations.vue'

const routes = [
    {
        path: '/new-integrations',
        name: 'Manage New Integrations',
        component: ManageNewIntegrations,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Manage New Integrations', layout: 'default-container' },
    },
    {
        path: '/player-metrics-whitelist',
        name: 'Player Metrics Whitelist',
        component: PlayerMetricsWhitelist,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Player Metrics Whitelist', layout: 'default-container' },
    },
    {
        path: '/survey-reporting',
        name: 'Survey Reporting',
        component: SurveyReporting,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Survey Reporting', layout: 'default-container' },
    },
    {
        path: '/ceate-npc',
        name: 'Create NPC',
        component: CreateNpc,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Create NPC', layout: 'default-container' },
    },
    {
        path: '/gamefam-portfolio-metrics',
        name: 'Gamefam Portfolio Metrics',
        component: GamefamPortfolioMetrics,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Gamefam Portfolio Metrics', layout: 'default-container' },
    },
    {
        path: '/manage-surveys',
        name: 'Manage Surveys',
        component: ManageSurveys,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Manage Surves', layout: 'default-container' },
    },
    {
        path: '/fortnite-integrations-reporting',
        name: 'Fortnite Integrations Reporting',
        component: FortniteIntegrationsReporting,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Fortnite Integrations Reporting', layout: 'default-container' },
    },
    {
        path: '/manage-fortnite-integrations',
        name: 'Manage Fortnite Integrations',
        component: ManageFortniteIntegrations,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Manage Fortnite Integrations', layout: 'default-container' },
    },
    {
        path: '/fortnite-portfolio-metrics',
        name: 'Fortnite Portfolio Metrics',
        component: FortnitePortfolioMetrics,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Fortnite Portfolio Metrics', layout: 'default-container' },
    },
    {
        path: '/programmatic-campaign-setup',
        name: 'Programmatic Campaign Setup',
        component: ManageCampaignPrimers,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Programmatic Campaign Setup', layout: 'default-container' },
    },
    {
        path: '/manage-integration-groups',
        name: 'Manage Integration Groups',
        component: ManageIntegrationGroups,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Manage Integration Groups', layout: 'default-container' },
    },
    {
        path: '/campaign-reporting',
        name: 'Campaign Reporting',
        component: CombinedReporting,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Campaign Reporting', layout: 'default-container' },
    },
    {
        path: '/manage-integrations',
        name: 'Manage Integrations',
        component: ManageIntegrations,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Manage Integrations', layout: 'default-container' },
    },
    {
        path: '/integrations-dashboard',
        name: 'Integrations Dashboard',
        component: IntegrationsDashboard,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Integrations Dashboard', layout: 'default-container' },
    },
    {
        path: '/dynamic-parameters',
        name: 'Dynamic Parameters',
        component: DynamicParameters,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Dynamic Parameters', layout: 'default-container' },
    },
    {
        path: '/sidebar-editor',
        name: 'Sidebar Editor',
        component: SidebarEditor,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Sidebar Editor', layout: 'default-container' },
    },
    {
        path: '/user-settings',
        name: 'User Settings',
        component: UserSettings,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | User Settings', layout: 'default-container' },
    },
    {
        path: '/daily-game-reporting',
        name: 'Daily Game Reporting',
        component: DailyGameReporting,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Daily Game Reporting', layout: 'default-container' },
    },
    {
        path: '/video-impressions-reporting',
        name: 'Video Impressions Reporting',
        component: VideoImpressionsReporting,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Video Impressions Reporting', layout: 'default-container' },
    },
    {
        path: '/iab-requirements',
        name: 'IAB Requirements',
        component: IabRequirements,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | IAB Requirements', layout: 'default-container' },
    },
    {
        path: '/historic-dashboard',
        name: 'Historic Dashboard',
        component: HistoricDashboard,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Historic Dashboard', layout: 'default-container' },
    },
    {
        path: '/manage-archive',
        name: 'Archive',
        component: ManageArchive,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Manage Archive', layout: 'default-container' },
    },
    {
        path: '/native-display-reporting',
        name: 'Native Display Reporting',
        component: NativeDisplayReporting,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Native Display Reporting', layout: 'default-container' },
    },
    {
        path: '/gamefam-game-stats',
        name: 'Gamefam Game Stats',
        component: GamefamGameStats,
        meta: { requiresAuth: true, title: 'AdMonitor | Gamefam Game Stats', layout: 'default-container' },
    },
    {
        path: '/dialog-maker',
        name: 'Dialog Maker',
        component: DialogMaker,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Dialog Maker', layout: 'default-container' },
    },
    {
        path: '/dialog',
        name: 'Dialog Viewer',
        component: Dialog,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Dialog', layout: 'default-container' },
    },
    {
        path: '/change-history',
        name: 'History Logs',
        component: ChangeHistory,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | History Logs', layout: 'default-container' },
    },
    {
        path: '/create-item/campaign',
        name: 'Create Campaign',
        component: CreateCampaign,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Create Item', layout: 'default-container' },
    },
    {
        path: '/create-item/placement',
        name: 'Create Placement',
        component: CreatePlacement,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Create Item', layout: 'default-container' },
    },
    {
        path: '/create-item/gif',
        name: 'Create Gif',
        component: CreateGif,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Create Item', layout: 'default-container' },
    },
    {
        path: '/create-item/video',
        name: 'Create Video',
        component: CreateVideo,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Create Video', layout: 'default-container' },
    },
    {
        path: '/create-item/creative',
        name: 'Create Creative',
        component: CreateCreative,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Create Item', layout: 'default-container' },
    },
    {
        path: '/create-item/video-to-roblox',
        name: 'Video To Roblox',
        component: CreateVideoToRoblox,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Create Item', layout: 'default-container' },
    },
    {
        path: '/manage-games',
        name: 'Manage Games',
        component: ManageGames,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Manage Games', layout: 'default-container', },
    },
    {
        path: '/manage-campaigns',
        name: 'Campaigns',
        component: ManageCampaigns,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Manage Campaigns', layout: 'default-container', },
    },
    {
        path: '/manage/:advert_type/:uuid',
        name: 'Manage Campaign',
        component: ManageAdvert,
        meta: { requiresAuth: true, title: 'AdMonitor | Manage Campaign', layout: 'default-container', },
    },
    {
        path: '/manage-item/:item_type/:uuid/:run_uuid?',
        name: 'Manage Item',
        component: ManageItem,
        meta: { requiresAuth: true, title: 'AdMonitor | Manage Placement', layout: 'default-container'}
    },
    {
        path: '/manage-all-items',
        name: 'Manage Billboards',
        component: ManageAllItems,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | Manage All Items', layout: 'default-container'}
    },
    {
        path: '/user-moderation',
        name: 'Moderate Users',
        component: UserModeration,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | User Moderation', layout: 'default-container', },
    },
    {
        path: '/user-permissions',
        name: 'User Permissions',
        component: UserPermissions,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | User Permissions', layout: 'default-container', },
    },
    {
        path: '/create-user',
        name: 'Create User',
        component: CreateUser,
        meta: { requiresRead: true, requiresAuth: true, title: 'AdMonitor | User Creator', layout: 'default-container', },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            layout: 'blank-container',
        },
    },
    {
        path: '/',
        name: 'Overview',
        component: Overview,
        meta: { requiresRead: true, requiresAuth: true, layout: 'default-container' },
    },
    {
        path: '/sign-up',
        name: 'Sign Up',
        component: SignUp,
    },
    {
        path: '/:pathMatch(.*)*',
        component: PageNotFound,
        meta: { layout: 'blank-container' },
    },
]

export default routes