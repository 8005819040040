<template>
  <div class="container mx-auto p-4">
    <!-- New Integrations List -->
    <table class="table-auto w-full mb-4">
      <thead>
        <tr class="bg-gray-200">
          <th class="px-4 py-2">Name</th>
          <th class="px-4 py-2">Start Date</th>
          <th class="px-4 py-2">End Date</th>
          <th class="px-4 py-2">Integration Codes CSV</th>
          <th class="px-4 py-2">Linked Native Display Campaign</th>
          <th class="px-4 py-2">Updated At</th>
          <th class="px-4 py-2">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="integration in integrations" 
          :key="integration.uuid" 
          :class="{ 'bg-blue-100': integration.uuid === activeRowId }"
          class="border-b"
        >
          <td class="px-4 py-2">{{ integration.name }}</td>
          <td class="px-4 py-2">{{ formatToMMddYYYY(integration.start_date) }}</td>
          <td class="px-4 py-2">{{ formatToMMddYYYY(integration.end_date) }}</td>
          <td class="px-4 py-2">{{ integration.integration_codes_csv }}</td>
          <td class="px-4 py-2">{{ integrationCampaignName(integration) }}</td>
          <td class="px-4 py-2">{{ formatToMMddYYYY(integration.updated_at) }}</td>
          <td class="px-4 py-2 flex flex-col items-start">
            <button 
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mb-2" 
              @click="editIntegration(integration)"
            >
              Edit
            </button>
            <button 
              class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded" 
              @click="deleteIntegration(integration.uuid)"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Add/Edit Integration Form -->
    <div v-if="showForm" class="mb-4">
      <form 
        @submit.prevent="submitForm" 
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
            Name
          </label>
          <input 
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            id="name" 
            type="text" 
            v-model="form.name" 
            placeholder="Integration Name"
          >
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="start-date">
            Start Date
          </label>
          <input 
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            id="start-date" 
            type="date" 
            v-model="form.start_date"
          >
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="end-date">
            End Date
          </label>
          <input 
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            id="end-date" 
            type="date" 
            v-model="form.end_date"
          >
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="integration-code">
            Integration Codes CSV
          </label>
          <input 
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            id="integration-code" 
            type="text" 
            v-model="form.integration_codes_csv" 
            placeholder="Enter Integration Code"
          >
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">
            Linked Native Display Campaign
          </label>
          <DropdownComponent 
            v-if="allCampaigns.length"
            :items="allCampaigns"
            display="name"
            :selected="currentCampaign ? currentCampaign.name : 'Select a Campaign'"
            @user_select="setCurrentCampaign"
            class="sm:w-96 w-72 self-center"
          />
        </div>

        <div class="flex items-center justify-between">
          <button 
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>

    <button 
      class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" 
      @click="addIntegration"
    >
      Add Integration
    </button>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "ManageNewIntegrations",
  components: {},
  data() {
    return {
      integrations: [],
      showForm: false,
      isEditMode: false,
      activeRowId: null,
      form: {
        name: '',
        start_date: '',
        end_date: '',
        integration_codes_csv: '',
        native_display_campaign_uuid: null,
      },
      allCampaigns: [],
      currentIntegrationId: null,
    }
  },
  computed: {
    currentCampaign() {
      return this.allCampaigns.find(c => c.uuid === this.form.native_display_campaign_uuid);
    }
  },
  created() {
    this.getAllCampaigns();
    this.fetchIntegrations();
  },
  methods: {
    async fetchIntegrations() {
      const url = '/new-integrations';
      const toasts = {
        success: 'New Integrations fetched successfully',
        error: 'Failed to fetch new integrations'
      };

      try {
        const response = await this.$http_request(this.$http.get, url, toasts);
        this.integrations = response.data.data;
      } catch (error) {
        console.error('Fetch New Integrations Error:', error);
      }
    },
    async getAllCampaigns() {
      try {
        const { data } = await this.$http.get('/campaigns/get/all');
        this.allCampaigns = data;
        this.allCampaigns.unshift({ name: 'No Linked ND Campaign', uuid: null });
      } catch (error) {
        console.error('Get All Campaigns Error:', error);
      }
    },
    addIntegration() {
      if (this.showForm && !this.isEditMode) {
        this.showForm = false;
        this.resetForm();
        this.activeRowId = null;
        return;
      }
      this.resetForm();
      this.activeRowId = null;
      this.showForm = true;
      this.isEditMode = false;
    },
    editIntegration(integration) {
      if (this.showForm && this.activeRowId === integration.uuid) {
        this.showForm = false;
        this.isEditMode = false;
        this.resetForm();
        this.activeRowId = null;
        return;
      }
      this.activeRowId = integration.uuid;
      this.form = { ...integration };
      this.showForm = true;
      this.isEditMode = true;
      this.currentIntegrationId = integration.uuid;
    },
    resetForm() {
      this.form = {
        name: '',
        start_date: '',
        end_date: '',
        integration_codes_csv: '',
        native_display_campaign_uuid: null,
      };
    },
    async submitForm() {
      const url = this.isEditMode 
        ? `/new-integrations/${this.currentIntegrationId}` 
        : '/new-integrations';
      const method = this.isEditMode ? this.$http.put : this.$http.post;
      const toasts = {
        success: `Integration ${this.isEditMode ? 'updated' : 'added'} successfully`,
        error: `Failed to ${this.isEditMode ? 'update' : 'add'} integration`
      };

      try {
        await this.$http_request(method, url, toasts, this.form);
        this.showForm = false;
        this.resetForm();
        this.fetchIntegrations();
        this.activeRowId = null;
      } catch (error) {
        console.error('Submit Form Error:', error);
        if (error.response && error.response.data && error.response.data.errors) {
          let messages = Object.values(error.response.data.errors)
            .map(e => e.join(' '))
            .join('\n');
          this.$notify({ type: "error", text: messages });
        } else {
          this.$notify({ type: "error", text: error.message });
        }
      }
    },
    async deleteIntegration(uuid) {
      if (confirm("Are you sure you want to delete this integration?")) {
        const url = `/new-integrations/${uuid}`;
        const toasts = {
          success: 'Integration successfully deleted.',
          error: 'Failed to delete integration.'
        };

        try {
          await this.$http_request(this.$http.delete, url, toasts);
          this.fetchIntegrations();
        } catch (error) {
          console.error('Delete Integration Error:', error);
        }
      }
    },
    setCurrentCampaign(campaign) {
      this.form.native_display_campaign_uuid = campaign.uuid;
    },
    integrationCampaignName(integration) {
      const defaultMessage = 'No Linked ND Campaign';
      if (!integration.native_display_campaign_uuid) return defaultMessage;
      return this.allCampaigns.find(c => c.uuid === integration.native_display_campaign_uuid)?.name || defaultMessage;
    },
    formatToMMddYYYY(dateStr) {
      if (!dateStr) return '';
      return moment.utc(dateStr).format('MM-DD-YYYY');
    },
  }
}
</script>

<style scoped>
/* Add component-specific styles here if needed */
</style>
