<template>
  <div class="bg-white shadow rounded-lg p-5">
    <div class="mt-4 flex space-x-2">
        <button 
            @click="fetchNpcs(pagination.current_page - 1)"
            :disabled="pagination.current_page <= 1">
            Previous
        </button>

        <button
            @click="fetchNpcs(pagination.current_page + 1)"
            :disabled="pagination.current_page >= pagination.last_page">
            Next
        </button>
    </div>
    <form class="space-y-6 divide-y divide-gray-200"
          @submit.prevent="createOrUpdateNpc">
      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label for="npc-uuid" 
               class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
          NPC UUID
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <input type="text" 
                 id="npc-uuid"
                 v-model="form.uuid"
                 :disabled="isEditing"
                 required 
                 class="max-w-lg block w-full shadow-sm 
                        focus:ring-indigo-500 focus:border-indigo-500
                        sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label for="npc-name" 
               class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
          NPC Name
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <input type="text"
                 id="npc-name"
                 v-model="form.name"
                 required 
                 class="max-w-lg block w-full shadow-sm 
                        focus:ring-indigo-500 focus:border-indigo-500
                        sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
        <label for="location" 
               class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
          Location
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <input type="text" 
                 id="location"
                 v-model="form.location" 
                 class="max-w-lg block w-full shadow-sm 
                        focus:ring-indigo-500 focus:border-indigo-500
                        sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label for="npc-appearance" 
               class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
          Appearance Info
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <textarea id="npc-appearance"
                    v-model="form.appearance_information"
                    rows="3"
                    class="max-w-lg block w-full shadow-sm 
                           focus:ring-indigo-500 focus:border-indigo-500
                           sm:text-sm border-gray-300 rounded-md">
          </textarea>
        </div>
      </div>

      <div class="pt-5 sm:col-span-2 sm:col-start-2">
        <button class="btn-primary indigo" type="submit">
          {{ isEditing ? 'Update NPC' : 'Create NPC' }}
        </button>
        <button v-if="isEditing" 
                class="btn-primary gray ml-2" 
                type="button" 
                @click="resetForm">
          Cancel
        </button>
      </div>
    </form>

    <!-- NPC List -->
    <div class="mt-8">
      <h2 class="text-lg font-bold mb-4">NPC List</h2>
      <div v-for="npc in npcs" :key="npc.uuid" 
           class="p-4 bg-gray-50 rounded-md mb-2 flex items-center justify-between">
        <div>
          <p class="font-semibold">{{ npc.name }}</p>
          <p class="text-sm text-gray-500">{{ npc.location }}</p>
          <p class="text-sm text-gray-500">{{ npc.appearance_information }}</p>
          <p class="font-semibold">{{ npc.uuid }}</p>
        </div>
        <div>
          <button @click="editNpc(npc)" 
                  class="btn-primary blue text-sm mr-2">
            Edit
          </button>
          <button @click="deleteNpc(npc.uuid)" 
                  class="btn-primary red text-sm">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      npcs: [],
      form: {
        uuid: '',
        name: '',
        appearance_information: '',
        location: ''
      },
        pagination: {
            current_page: 1,
            last_page: 1,
            per_page: 10,
            total: 0
        },
      isEditing: false
    }
  },
  methods: {
    async fetchNpcs(page = 1) {
        try {
            // Example: appending ?page=1, etc. for pagination
            const { data } = await axios.get(`/npcs?page=${page}`)
            // data.data will contain the NPC array
            // data.current_page, data.last_page, data.total, etc. for pagination controls
            this.npcs = data.data
            this.pagination = {
            current_page: data.current_page,
            last_page: data.last_page,
            per_page: data.per_page,
            total: data.total
            }
        } catch (error) {
            console.error(error)
        }
    },

    async createOrUpdateNpc() {
      try {
        if (this.isEditing) {
          // Update
          await axios.put(`/npcs/${this.form.uuid}`, {
            name: this.form.name,
            appearance_information: this.form.appearance_information,
            location: this.form.location
          })
        } else {
          // Create
          await axios.post('/npcs', {
            uuid: this.form.uuid,
            name: this.form.name,
            appearance_information: this.form.appearance_information,
            location: this.form.location
          })
        }
        this.resetForm()
        this.fetchNpcs()
      } catch (error) {
        console.error(error)
      }
    },
    editNpc(npc) {
      this.isEditing = true
      this.form.uuid = npc.uuid
      this.form.name = npc.name
      this.form.appearance_information = npc.appearance_information
      this.form.location = npc.location
    },
    async deleteNpc(uuid) {
      if (!confirm('Are you sure you want to delete this NPC?')) {
        return
      }
      try {
        await axios.delete(`/npcs/${uuid}`)
        this.fetchNpcs()
      } catch (error) {
        console.error(error)
      }
    },
    resetForm() {
      this.isEditing = false
      this.form.uuid = ''
      this.form.name = ''
      this.form.appearance_information = ''
      this.form.location = ''
    }
  },
  async mounted() {
    await this.fetchNpcs()
  }
}
</script>

<style scoped>
.btn-primary {
  @apply inline-block px-4 py-2 rounded text-white;
}
.btn-primary.indigo {
  @apply bg-indigo-600 hover:bg-indigo-700;
}
.btn-primary.blue {
  @apply bg-blue-600 hover:bg-blue-700;
}
.btn-primary.red {
  @apply bg-red-600 hover:bg-red-700;
}
.btn-primary.gray {
  @apply bg-gray-600 hover:bg-gray-700;
}
</style>
