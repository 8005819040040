<template>
	<div class="container mx-auto p-4">
		<!-- Create Form -->
		<div class="mb-6 bg-white p-4 rounded shadow">
			<h1 class="text-xl font-semibold mb-4">
				Add Player Metric Whitelist (Most Recent Is Active)
			</h1>
			<form @submit.prevent="createEntry" class="space-y-4">
				<div>
					<label for="csv" class="block text-gray-700">
						Universe IDs (CSV):
					</label>
					<textarea
						id="csv"
						v-model="form.universe_id_whitelist_csv"
						rows="3"
						placeholder="Enter CSV values"
						class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
					></textarea>
				</div>
				<div>
					<button
						type="submit"
						class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
					>
						Create Entry
					</button>
				</div>
			</form>
		</div>

		<!-- Data Table -->
		<div class="bg-white p-4 rounded shadow">
			<h2 class="text-lg font-medium mb-2">Player Metrics Entries</h2>
			<TableComponent
				:table_data="entries"
				:show_total_row="false"
				:show_average_row="false"
                default_sort_by="Order"
                :default_sort_desc="false"
			></TableComponent>
		</div>
	</div>
</template>

<script>
import TableComponent from '@/components/TableComponent.vue';

export default {
	name: 'PlayerMetrics',
	components: {
		TableComponent,
	},
	data() {
		return {
			entries: [],
			form: {
				uuid: '',
				universe_id_whitelist_csv: '',
			},
		};
	},
	methods: {
		fetchEntries() {
			this.$http
				.get('/player-metrics-whitelist')
				.then((response) => {
					this.entries = response.data.data;
					// Pre-fill the form with CSV from the most recent record if available
					if (this.entries.length > 0) {
						const mostRecent = this.entries.reduce((latest, entry) => {
							return new Date(latest['Updated At']) < new Date(entry['Updated At'])
								? entry
								: latest;
						}, this.entries[0]);
						this.form.universe_id_whitelist_csv =
							mostRecent['Universe ID Whitelist CSV'];
					}
				})
				.catch((error) => {
					console.error('Error fetching entries:', error);
				});
		},
		createEntry() {
			const payload = {
				uuid: this.form.uuid || undefined,
				universe_id_whitelist_csv: this.form.universe_id_whitelist_csv,
			};
			this.$http
				.post('/player-metrics-whitelist', payload)
				.then((response) => {
					// Add new entry at the beginning of the entries array
					this.entries.unshift(response.data);
					// Reset form but pre-fill CSV with the newly created entry's CSV
					this.form = {
						uuid: '',
						universe_id_whitelist_csv: response.data['Universe ID Whitelist CSV'],
					};
				})
				.catch((error) => {
					console.error('Error creating entry:', error);
				});
		},
	},
	mounted() {
		this.fetchEntries();
	},
};
</script>

<style scoped>
/* Additional scoped styles if needed */
</style>