// store/SurveySystemNpcModule.js
import axios from 'axios';
import { notify } from '@kyvg/vue3-notification';

const state = {
  npcs: [],
  loading: false,
};

const mutations = {
  SET_NPCS(state, npcs) {
    state.npcs = npcs;
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
};

const actions = {
  /**
   * Fetch all NPCs from the server.
   */
  async fetchNpcs({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await axios.get('/npcs');
      commit('SET_NPCS', response.data.data ?? response.data);
    } catch (error) {
      console.error('Error fetching NPCs:', error);
      commit('SET_NPCS', []);
      notify({ title: 'Error', text: 'Failed to load NPCs', type: 'error' });
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * Link a specific NPC to one or more surveys.
   */
  async linkNpcToSurveys({dispatch}, { npcUuid, surveyUuids }) {
    dispatch()
    if (!npcUuid || !surveyUuids?.length) return;
    try {
      await axios.post(`/npcs/${npcUuid}/linkSurveys`, {
        survey_uuids: surveyUuids,
      });
      notify({ title: 'Success', text: 'NPC linked to surveys', type: 'success' });
      // If you need updated data, re-fetch either npcs or surveys:
      // e.g., dispatch('fetchNpcs') or dispatch('surveys/fetchSurvey', someSurveyId, { root: true });
    } catch (error) {
      console.error('Error linking NPC to surveys:', error);
      notify({ title: 'Error', text: 'Failed to link NPC', type: 'error' });
    }
  },

  /**
   * Unlink a specific NPC from one or more surveys.
   */
  async unlinkNpcFromSurveys({ dispatch }, { npcUuid, surveyUuids }) {
    dispatch()
    if (!npcUuid || !surveyUuids?.length) return;
    try {
      await axios.post(`/npcs/${npcUuid}/unlinkSurveys`, {
        survey_uuids: surveyUuids,
      });
      notify({ title: 'Success', text: 'NPC unlinked from surveys', type: 'success' });
      // If needed, re-fetch state here as well...
    } catch (error) {
      console.error('Error unlinking NPC from surveys:', error);
      notify({ title: 'Error', text: 'Failed to unlink NPC', type: 'error' });
    }
  },
};

const getters = {
  getNpcById: (state) => (uuid) => {
    return state.npcs.find((npc) => npc.uuid === uuid);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
