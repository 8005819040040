<template>
    <div class="p-4">
        <!-- Overview Stats -->
        <div class="flex flex-col gap-2 justify-center items-center h-full w-full" v-if="overviewLoading">
            <h2>
                Fetching overview data... This might take a few minutes.
            </h2>
            <LoadingComponent />
            </div>
            <div class="w-full p-4 bg-white rounded-lg shadow-md" v-else>
            <h3 class="text-lg font-medium text-gray-800 mb-2">
                Roblox Stats (Last 90 days)
            </h3>
            <ul class="grid grid-cols-2">
                <span>
                <li
                    v-for="stat in robloxOverviewData.global"
                    :key="stat.name"
                    class="flex text-gray-700 mb-2"
                >
                    <span class="mr-2">{{ stat.name }}:</span>
                    <span class="font-medium">{{ stat.value }}</span>
                </li>
                </span>
                <span>
                <li
                    v-for="stat in robloxOverviewData.us"
                    :key="stat.name"
                    class="flex text-gray-700 mb-2"
                >
                    <span class="mr-2">{{ stat.name }}:</span>
                    <span class="font-medium">{{ stat.value }}</span>
                </li>
                </span>
            </ul>
        </div>


        <TabButtons :buttons="panelSwitchingButtons" :active_button="currentlyVisiblePanel" :panel-changed-method="panelChanged"></TabButtons>
                
        <div class="flex flex-col gap-2 justify-center items-center h-full" v-if="loading">
            <h2> 
                Fetching analytics data... This might take a few minutes. 
            </h2>
            <LoadingComponent />
        </div>
        <div class="flex flex-col gap-2 justify-center items-center h-full" v-if="gender_loading && currentlyVisiblePanel == 'gender_table'">
            <h2> 
                Fetching gender data... This might take a few minutes. 
            </h2>
            <LoadingComponent />
        </div>

                <!-- Gender Table (Doesn't need interval selector) -->
        <TableComponent v-if="this.currentlyVisiblePanel === 'gender_table' && !this.gender_loading" :table_data="genderTableData" default_sort_by="Name" :default_sort_desc="false" :exported_file_name="'gender-percentages-per-game'" :show_total_row="false" :show_average_row="false"></TableComponent>

        <div v-if="this.currentlyVisiblePanel === 'age_table'">
            <div class="flex flex-col gap-2 justify-center items-center h-full" v-if="age_loading">
                <h2> 
                    Fetching age data... This might take a few minutes. 
                </h2>
                <LoadingComponent />
            </div>
            <!-- Age table (Doesn't need interval selector) -->
            <h5 class="p-3 m-1"> (Age table has had 'Unknown' column removed) </h5>
            <TableComponent v-if="!this.age_loading" :table_data="ageTableData" total_column_heading_name="Friendly Name" default_sort_by="Friendly Name" :default_sort_desc="false" :exported_file_name="'age-percentages-per-game'" :show_total_row="false" :show_average_row="false"></TableComponent>
        </div>

        <div class="mt-4" v-if="(this.currentlyVisiblePanel === 'main_table' || this.currentlyVisiblePanel === 'us_table') && !this.loading">
            <!-- Date Range Selector -->
            <div class="flex flex-col gap-4 mb-4">
            <!-- Date Inputs and Fetch Button -->
            <div class="flex items-center gap-4">
                <!-- Start/End Date Inputs -->
                <div class="flex items-center">
                    <label class="mr-2 font-medium">Start Date:</label>
                    <input
                        type="date"
                        v-model="startDate"
                        class="p-2 border rounded-md mr-4"
                    />
                    <label class="mr-2 font-medium">End Date:</label>
                    <input
                        type="date"
                        v-model="endDate"
                        class="p-2 border rounded-md"
                    />
                </div>

                <!-- Submit Analytics Data Button -->
                <button
                    type="submit"
                    @click="fetchAnalyticsData"
                    class="py-1 px-4 bg-green-500 text-white rounded-md hover:bg-green-600"
                    >
                    Submit
                </button>

            </div>

            <!-- Buttons for Quick Ranges -->
            <div class="flex gap-2">
                <button
                    @click="setRange(7)"
                    class="py-1 px-2 bg-blue-200 text-blue-800 hover:bg-blue-300 rounded"
                >
                    Last 7 Days
                </button>
                <button
                    @click="setRange(30)"
                    class="py-1 px-2 bg-blue-200 text-blue-800 hover:bg-blue-300 rounded"
                >
                    Last 30 Days
                </button>
                <button
                    @click="setRange(60)"
                    class="py-1 px-2 bg-blue-200 text-blue-800 hover:bg-blue-300 rounded"
                >
                    Last 60 Days
                </button>
                <button
                    @click="setRange(90)"
                    class="py-1 px-2 bg-blue-200 text-blue-800 hover:bg-blue-300 rounded"
                >
                    Last 90 Days
                </button>
            </div>
        </div>

            <!-- Combined Portfolio Data Table -->
            <div>
                <h2 class="text-xl font-semibold mt-6 mb-4">Portfolio Data</h2>
                <TableComponent
                    v-if="combinedPortfolioData.length > 0"
                    :table_data="combinedPortfolioData"
                    :default_sort_by="'Total Visits'"
                    :default_sort_desc="true"
                    :show_total_row="true"
                    :show_average_row="true"
                    :exported_file_name="`portfolio-data-${startDate}_to_${endDate}`"
                    :total_column_heading_name="'Name'"
                />
                <p v-else class="text-gray-500">
                    No data available for the selected date range.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import TableComponent from '@/components/TableComponent.vue';
import gamefamPortfolioData from "@/static_data/gamefam_portfolio_data.json";
import TabButtons from '../components/TabButtons.vue'

export default {
    name: "PortfolioAnalytics",
    components: {
        TableComponent,
        TabButtons
    },
    data() {
        return {
            loading: false,
            overviewLoading: false,
            startDate: null,
            endDate: null,
            currentlyVisiblePanel: 'main_table',
            portfolioVisits: [], // For visits data
            portfolioDau: [], // For DAU data
            portfolioMau: [], // For MAU data
            portfolioSessionLength: [], // For session length data
            portfolioCountryPercentageBreakdown: [], // For country percentage breakdown
            robloxOverviewData: [], // For Roblox Overview Data
            genderTableData: [], // For Gender Data 
            ageTableData: [], // For Age Data
            panelSwitchingButtons: [
                {
                    name: 'main_table',
                    text: 'Main Table'
                },
                {
                    name: 'age_table',
                    text: 'Age %'
                },
                {
                    name: 'gender_table',
                    text: 'Gender %'
                }
            ],
        };
    },
    computed: {
        /**
         * Combines all portfolio data into a single table.
         * Each row is merged by place_id (and shares the same name/universe_id).
         * We only create columns for these alpha-2 codes:
         *   US, AU, BR, CA, DE, ES, FR, GB, IT, MX
         * plus an aggregate "ROW" for all other codes.
         *
         * "Place ID" and "Universe ID" are created internally for reference,
         * but are removed before returning final data so they do not appear in the table.
         */
        combinedPortfolioData() {
            const dataMap = {};
            const alpha2Codes = ["US", "AU", "BR", "CA", "DE", "ES", "FR", "GB", "IT", "MX"];

            const parsePercentage = (str) => {
                if (!str || typeof str !== "string") return 0;
                const val = parseFloat(str.replace("%", "").trim());
                return Number.isNaN(val) ? 0 : Math.round(val);
            };

            const getDaySpan = (startDate, endDate) => {
                if (!startDate || !endDate) return 30;
                const start = new Date(startDate);
                const end = new Date(endDate);
                const diffMs = end - start;
                if (diffMs <= 0) return 30;
                return Math.ceil(diffMs / (1000 * 60 * 60 * 24));
            };

            const getAvgDailyVisits = (totalVisits, daySpan) => {
                if (!totalVisits || !daySpan) return 0;
                return Math.round(totalVisits / daySpan);
            };

            const ensureEntry = (item) => {
                if (!dataMap[item.place_id]) {
                    dataMap[item.place_id] = {
                        "Place ID": item.place_id,
                        Name: item.name ?? null,
                        "Universe ID": item.universe_id ?? null,
                        "Total Visits": 0,
                        "Avg. Daily Visits": 0,
                        "Average DAU": 0,
                        "Monthly Active Users": 0,
                        "Session Length (mins)": 0,
                        US: 0, AU: 0, BR: 0, CA: 0, DE: 0, ES: 0, FR: 0, GB: 0, IT: 0, MX: 0, ROW: 0,
                        // We'll mark static data to skip any dynamic re-calculations:
                        staticSource: false
                    };
                }
            };

            // Merge dynamic portfolio data first
            this.portfolioVisits.forEach(item => {
                ensureEntry(item);
                dataMap[item.place_id]["Total Visits"] = Number(item.total_visits) || 0;
            });
            this.portfolioDau.forEach(item => {
                ensureEntry(item);
                dataMap[item.place_id]["Average DAU"] = Number(item.average_dau) || 0;
            });
            this.portfolioMau.forEach(item => {
                ensureEntry(item);
                dataMap[item.place_id]["Monthly Active Users"] = Number(item.average_mau) || 0;
            });
            this.portfolioSessionLength.forEach(item => {
                ensureEntry(item);
                dataMap[item.place_id]["Session Length (mins)"] = Number(item.average_session_length) || 0;
            });

            // Merge country breakdown (dynamic)
            const parseCountryBreakdown = (item) => {
                const copy = { ...item };
                delete copy.place_id;
                delete copy.universe_id;
                delete copy.name;
                if ("Total" in copy) delete copy.Total;
                return copy;
            };

            this.portfolioCountryPercentageBreakdown.forEach(item => {
                ensureEntry(item);
                const breakdown = parseCountryBreakdown(item);
                Object.entries(breakdown).forEach(([country, raw]) => {
                    const pct = parsePercentage(raw);
                    if (alpha2Codes.includes(country)) {
                        dataMap[item.place_id][country] += pct;
                    } else {
                        dataMap[item.place_id].ROW += pct;
                    }
                });
            });

            // Merge static Gamefam data
            const daySpan = getDaySpan(this.startDate, this.endDate);
            gamefamPortfolioData.forEach(item => {
                const placeId = item.Game || `static_${Date.now()}`;
                ensureEntry({ place_id: placeId, name: item.Game });
                dataMap[placeId].staticSource = true;

                // For static data:
                // - "Avg. Daily Visits" comes directly from "Daily Visits"
                // - "Total Visits" is "Daily Visits" * daySpan
                // - "Average DAU" comes from "Avg. DAU"
                const dailyVisits = Number(item["Daily Visits"]) || 0;
                dataMap[placeId]["Avg. Daily Visits"] = dailyVisits;
                dataMap[placeId]["Total Visits"] = dailyVisits * daySpan;
                dataMap[placeId]["Average DAU"] = Number(item["Avg. DAU"]) || 0;
                dataMap[placeId]["Monthly Active Users"] = Number(item["Monthly Active Users"]) || 0;
                dataMap[placeId]["Session Length (mins)"] = parseFloat(item["Session Length (mins)"]) || 0;

                // Merge Geo Breakdown
                const geo = item["Geo Breakdown"] || {};
                Object.entries(geo).forEach(([countryCode, rawValue]) => {
                    const pct = parsePercentage(rawValue);
                    if (alpha2Codes.includes(countryCode)) {
                        dataMap[placeId][countryCode] += pct;
                    } else {
                        dataMap[placeId].ROW += pct;
                    }
                });
            });

            // Now compute Avg. Daily Visits for non-static data
            const allEntries = Object.values(dataMap);
            allEntries.forEach(entry => {
                if (!entry.staticSource) {
                    entry["Avg. Daily Visits"] = getAvgDailyVisits(entry["Total Visits"], daySpan);
                }
            });

            // Append '%' to country breakdown fields and remove unused keys
            allEntries.forEach(row => {
                alpha2Codes.forEach(code => {
                    row[code] = `${row[code]}%`;
                });
                row.ROW = `${row.ROW}%`;
                delete row["Place ID"];
                delete row["Universe ID"];
                delete row.staticSource;
            });

            // Reorder columns: Place 'Total Visits', 'Avg. Daily Visits', 'Average DAU' consecutively.
            const orderedEntries = allEntries.map(row => {
                return {
                    Name: row.Name,
                    "Total Visits": row["Total Visits"],
                    "Avg. Daily Visits": row["Avg. Daily Visits"],
                    "Average DAU": row["Average DAU"],
                    "Monthly Active Users": row["Monthly Active Users"],
                    "Session Length (mins)": row["Session Length (mins)"],
                    US: row.US,
                    AU: row.AU,
                    BR: row.BR,
                    CA: row.CA,
                    DE: row.DE,
                    ES: row.ES,
                    FR: row.FR,
                    GB: row.GB,
                    IT: row.IT,
                    MX: row.MX,
                    ROW: row.ROW,
                };
            });

            return orderedEntries;
        },
    },
    methods: {
        fetchAgeTableData(){
            this.ageLoading = true
            this.$http.get(`/portfolio/age-table`)
                .then(({data}) => {
                    this.ageTableData = data
                    this.$notify({type: 'success', text: 'Fetched age table data'})
                })
                .catch((error) => {
                    this.$notify({type: 'error', text: 'Failed to fetch age table data'})
                    console.error(error.message)
                })
                .finally(() => {
                    this.ageLoading = false
                })
        },
        fetchGenderTableData(){
            this.$http.get(`/portfolio/genders-table`)
                .then(({data}) => {
                    this.genderTableData = data
                    this.gender_loading = false
                    this.$notify({type: 'success', text: 'Fetched gender table data'})
                })
                .catch((error) => {
                    this.gender_loading = false
                    console.error(error.message)
                    this.$notify({type: 'error', text: 'Failed to fetch gender table data'})
                })
        },
        panelChanged(panelName){
            this.currentlyVisiblePanel = panelName
        },
        /**
         * Fetch Roblox Overview Data
         */
        fetchRobloxOverviewData(){
            this.overviewLoading = true
            this.$http.get(`/roblox-overview`)
                .then(({data}) => {
                    this.robloxOverviewData = data
                    this.$notify({type: 'success', text: 'Fetched Roblox overview'})
                })
                .catch((error) => {
                    this.$notify({type: 'error', text: 'Failed to fetch Roblox overview data'})
                    console.error(error.message)
                })
                .finally(() => {
                    this.overviewLoading = false
                }) 
        },
        /**
         * Format a Date object (or timestamp) as YYYY-MM-DD
         */
        formatDate(dateObj) {
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        /**
         * Set the date range based on "days" (e.g., 7, 30, 60, 90),
         * counting back from yesterday.
         */
        setRange(days) {
            const today = new Date();
            // One day ago
            const oneDayAgo = new Date(today.getTime() - 24 * 60 * 60 * 1000);
            // Subtract (days - 1) from oneDayAgo to get the start date
            const rangeStart = new Date(oneDayAgo.getTime() - (days - 1) * 24 * 60 * 60 * 1000);

            this.startDate = this.formatDate(rangeStart);
            this.endDate = this.formatDate(oneDayAgo);
            this.fetchAnalyticsData();
        },

        /**
         * Fetch the analytics data for the given date range
         */
        async fetchAnalyticsData() {
            if (!this.startDate || !this.endDate) {
                alert('Please select a start and end date.');
                return;
            }

            console.log('Fetching analytics data for date range:', this.startDate, this.endDate);

            try {
                this.loading = true;
                // Fetch all metrics simultaneously
                const [
                    visitsResponse,
                    dauResponse,
                    mauResponse,
                    sessionLengthResponse,
                    countryPercentageBreakdown
                ] = await Promise.all([
                    axios.get('/portfolio/visits', {
                        params: {
                            startDate: this.startDate,
                            endDate: this.endDate,
                        },
                        headers: { Accept: 'application/json' },
                    }),
                    axios.get('/portfolio/dau', {
                        params: {
                            startDate: this.startDate,
                            endDate: this.endDate,
                        },
                        headers: { Accept: 'application/json' },
                    }),
                    axios.get('/portfolio/mau', {
                        params: {
                            startDate: this.startDate,
                            endDate: this.endDate,
                        },
                        headers: { Accept: 'application/json' },
                    }),
                    axios.get('/portfolio/average-session-length', {
                        params: {
                            startDate: this.startDate,
                            endDate: this.endDate,
                        },
                        headers: { Accept: 'application/json' },
                    }),
                    axios.get('/portfolio/country-percentage-breakdown', {
                        params: {
                            startDate: this.startDate,
                            endDate: this.endDate,
                        },
                        headers: { Accept: 'application/json' },
                    }),
                ]);

                console.log('Visits Data:', visitsResponse.data);
                console.log('DAU Data:', dauResponse.data);
                console.log('MAU Data:', mauResponse.data);
                console.log('Session Length Data:', sessionLengthResponse.data);
                console.log('Country Percentage Breakdown:', countryPercentageBreakdown.data);

                // Process visits data
                if (Array.isArray(visitsResponse.data)) {
                    this.portfolioVisits = visitsResponse.data.map((item) => ({
                        place_id: item.place_id,
                        name: item.name,
                        universe_id: item.universe_id,
                        total_visits: item.total_visits,
                    }));
                } else {
                    console.error('Unexpected format for visits data:', visitsResponse.data);
                    alert('Failed to fetch portfolio visits due to unexpected data format.');
                }

                // Process DAU data
                if (Array.isArray(dauResponse.data)) {
                    this.portfolioDau = dauResponse.data.map((item) => ({
                        place_id: item.place_id,
                        name: item.name,
                        universe_id: item.universe_id,
                        average_dau: item.average_dau,
                    }));
                } else {
                    console.error('Unexpected format for DAU data:', dauResponse.data);
                    alert('Failed to fetch portfolio DAU due to unexpected data format.');
                }

                // Process MAU data
                if (Array.isArray(mauResponse.data)) {
                    this.portfolioMau = mauResponse.data.map((item) => ({
                        place_id: item.place_id,
                        name: item.name,
                        universe_id: item.universe_id,
                        average_mau: item.average_mau,
                    }));
                } else {
                    console.error('Unexpected format for MAU data:', mauResponse.data);
                    alert('Failed to fetch portfolio MAU due to unexpected data format.');
                }

                // Process session length data
                if (Array.isArray(sessionLengthResponse.data)) {
                    this.portfolioSessionLength = sessionLengthResponse.data.map((item) => ({
                        place_id: item.place_id,
                        name: item.name,
                        universe_id: item.universe_id,
                        average_session_length: item.average_session_length,
                    }));
                } else {
                    console.error('Unexpected format for session length data:', sessionLengthResponse.data);
                    alert('Failed to fetch session length data due to unexpected data format.');
                }

                // Process country breakdown data
                if (Array.isArray(countryPercentageBreakdown.data)) {
                    this.portfolioCountryPercentageBreakdown = countryPercentageBreakdown.data.map((item) => ({
                        place_id: item.place_id,
                        universe_id: item.universe_id,
                        name: item.name,
                        ...item
                    }));
                } else {
                    console.error('Unexpected format for Country Percentage data:', countryPercentageBreakdown.data);
                    alert('Failed to fetch country percentage breakdown due to unexpected data format.');
                }
            } catch (error) {
                console.error('Error fetching analytics data:', error);
                alert('Failed to fetch analytics data. Please try again.');
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.fetchRobloxOverviewData()
        this.fetchGenderTableData()
        this.fetchAgeTableData()
        // On mount, set default to last 60 days (counting back from yesterday) 
        const today = new Date();
        const oneDayAgo = new Date(today.getTime() - 24 * 60 * 60 * 1000); // yesterday
        // 60 days prior to that (59 days difference if we count "oneDayAgo" as day #1)
        const sixtyDaysAgo = new Date(oneDayAgo.getTime() - 59 * 24 * 60 * 60 * 1000);

        this.startDate = this.formatDate(sixtyDaysAgo);
        this.endDate = this.formatDate(oneDayAgo);

        // Automatically fetch data once default range is set
        this.fetchAnalyticsData();
    },
};
</script>

<style scoped>
/* Additional styling can go here if needed */
</style>
